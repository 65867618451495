import CryptoJS from 'crypto-js';

const AES_ENCRYPTION_KEY = process.env.REACT_APP_USER_CREDENTIALS;

if (!AES_ENCRYPTION_KEY || AES_ENCRYPTION_KEY.length !== 16) {
    throw new Error('AES_ENCRYPTION_KEY must be exactly 16 bytes long.');
}

export function encrypt(payload) {
    try {
        const stringifiedPayload = JSON.stringify(payload); // Convert to string before encrypting
        const encrypted = CryptoJS.AES.encrypt(
            stringifiedPayload,
            CryptoJS.enc.Utf8.parse(AES_ENCRYPTION_KEY),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        return encrypted.toString(); // Return encrypted string
    } catch (error) {
        // console.error("Encryption error:", error);
        throw new Error("Encryption failed");
    }
}

export function decrypt(encryptedText) {
    // console.log("encryptedText:", encryptedText); // Debugging encrypted data
    try {
        // Decrypting the Base64-encoded encrypted text
        const decrypted = CryptoJS.AES.decrypt(
            encryptedText, // Encrypted data (Base64 string)
            CryptoJS.enc.Utf8.parse(AES_ENCRYPTION_KEY),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        // console.log("Decrypted WordArray:", decrypted); // Check the WordArray result

        // Convert the WordArray into a UTF-8 string
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        // console.log("Decrypted Text (UTF-8):", decryptedText);

        // If the decrypted text is a valid JSON string, parse it
        return JSON.parse(decryptedText); // Convert back to an object
    } catch (error) {
        // console.error("Decryption error:", error);
        throw new Error("Decryption failed");
    }
}