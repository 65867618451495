
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
export function Navigate() {
    const navigate = useNavigate();
  const navigatedata=  () => {
    navigate('/')
  }
  useEffect(()=>{
    navigatedata()
  })
 
  
}