import { useEffect, useState } from "react";
import ActionButton from "../../../components/partner-application/button/ActionButton";
import { AddWhiteSVG } from "../../../assets/svg";
import { useNavigate } from "react-router-dom";
import Listing from "../../../components/sales-application/Listing/Listing";
import Filters from "../../../components/sales-application/Filters/Filters";
import BreadCrumb from "../../../components/partner-application/breadcrumb/BreadCrumb";
import LeadList from "../../sales-application/LeadList/LeadListRetailers";
import Pagination from "@mui/material/Pagination";
import { PartnerService } from "../../../services/api/partnerService";
import { Toaster } from "../../../utils/utilities/Toaster";
import { LoaderService } from "../../../utils/utilities/loaderService";
import Funnel from "./Funnel";
import Dropdown from "../dropdown/Dropdown";
import Chart from "./Chart";

const breadCrumbData = [{ name: "Retailer List", path: "" }];

const filterValues = ["All", "Due", "Paid"];
const searchList = ["Mobile No", "Name", "ID"];
const columns = [
  { id: "id", label: "ID", minWidth: 70 },
  { id: "name", label: "Retailers Name", minWidth: 130 },
  { id: "mobile", label: "Mobile", minWidth: 130 },
  {id:'invoice_count', label:'Invoice Count', minWidth:80},
  { id: "total_paid_amount", label: "Paid Amount", minWidth: 130 },
  { id: "total_due_amount", label: "Due Amount", minWidth: 130 },
  { id: "total_invoice_amount", label: "Total Amount", minWidth: 130 },
  
];
const Retailer = () => {
  const partnerService = new PartnerService();
  const loader = new LoaderService();
  var roleUser = "";
  const navigate = useNavigate();
  const toaster = new Toaster();
  const userRole = localStorage.getItem("currentRole");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [fiveTopRetailersFlag, setFiveTopRetailersFlag] = useState(false);
  const [funnelData, setFunnelData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [funnelType, setFunnelType] = useState("");
  const [list, setList] = useState([]);
  const [isMobile,setIsMobile] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [permissionData, setPermissionData] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fetchOnboardingList(search, filter, pageNo, funnelType);
    const storedData = localStorage.getItem("Retailerpermissions");
    if (storedData) {
      const retrievedArray = JSON.parse(storedData);
      setPermissionData(retrievedArray);
    }
  }, []);
  const hasPermission = (requiredPermission) => {
    return permissionData.some(
      (permission) => permission.permission === requiredPermission
    );
  };
  const fetchOnboardingList = (search, filter, pageNo, funnelType) => {
    const partnerId = localStorage.getItem("currentPartnerId");
    // loader.showLoader();
    const filterdata = filter === "All" ? "" : filter;

    if (filterdata === "" && search !== "") {
      partnerService
        .fetchRetailsListSearch(
          search,
          filterdata,
          pageNo,
          partnerId,
          funnelType
        )
        .then((response) => {
          //loader.hideLoader();
          setTotalRecords(response?.data?.total);
          setList(response?.data?.data);
          setFunnelData(response?.data?.total_counts);
        })
        .catch((error) => {
          // if (error?.response?.data?.code === "token_not_valid") {
          //   localStorage.clear();
          //   navigate(`/`);
          // } else {
          //   //loader.hideLoader();
          //   //toaster.show(true, error?.response?.data?.message);
          // }
        });
    } else {
      partnerService
        .fetchRetailsList(search, filterdata, pageNo, partnerId, funnelType)
        .then((response) => {
          //loader.hideLoader();
          setTotalRecords(response?.data?.total);
          setList(response?.data?.data);
          setFunnelData(response?.data?.total_counts);
        })
        .catch((error) => {
          // if (error?.response?.data?.code === "token_not_valid") {
          //   localStorage.clear();
          //   navigate(`/`);
          // } else {
          // // loader.hideLoader();
          //   //toaster.show(true, error?.response?.data?.message);
          // }
        });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const fetchTopfiveRetailsListdata = (filter) => {
    const partnerId = localStorage.getItem("currentPartnerId");
   // loader.showLoader();
    const filterdata = filter === "All" ? "All" : filter;
    partnerService
      .fetchTopfiveRetailsList(filterdata, partnerId)
      .then((response) => {
        //loader.hideLoader();
        setTotalRecords(response?.data?.total);
        setList(response?.data?.data);
        setFunnelData(response?.data?.total_counts);
      })
      .catch((error) => {
        // if (error?.response?.data?.code === "token_not_valid") {
        //   localStorage.clear();
        //   navigate(`/`);
        // } else {
        //   //loader.hideLoader();
        //  // toaster.show(true, error?.response?.data?.message);
        // }
      });
  };
  // const funnelData = {
  //   invoice_count: "23",
  //   due_amount: "24",
  //   paid_amount: "28",
  //   transaction_amount: "30",
  // };
  const createNewPartner = () => {
    navigate("/retailer/create");
  };

  const handlePaginationChange = (event, value) => {
    setPageNo(value);
    fetchOnboardingList(search, filter, value, funnelType);
  };

  const handleFilterClick = (filter = "", text = "") => {
    setSearch(text);
    setFilter(filter);

    fetchOnboardingList(text, filter, pageNo, funnelType);
  };
  const handlesubmit = (id, active) => {};
  const handleFilterSearch = (value) => {
    setSearch("");
    setFilter("");

    fetchOnboardingList(value, filter, pageNo, funnelType);
  };
  const handleFunnelInput = (key, value) => {
    setFunnelType(value);
    if (fiveTopRetailersFlag) {
      fetchTopfiveRetailsListdata(value);
    } else {
      fetchOnboardingList(search, filter, pageNo, value);
    }
  };
  const createCampagins = () => {
    navigate("/campagins");
  };
  const fetchFiveTopRetailers = () => {
    setFiveTopRetailersFlag(true);
    fetchTopfiveRetailsListdata(funnelType);
  };
  const onResetData = () => {
    setFiveTopRetailersFlag(false);
    fetchOnboardingList(search, filter, pageNo, funnelType);
  };
  return (
    <div className="p-4">
      <div className="d-flex justify-content-between">
        <div style={{ fontSize: "24px", fontWeight: 700, color: "black" }}>
          <BreadCrumb breadCrumbData={breadCrumbData} />
        </div>
        {hasPermission("Create") && (
          <>
           
            {/* <div onClick={createCampagins}>
          <ActionButton
            isActive={true}
            buttonContent={
              <>
                <AddWhiteSVG /> Campaigns
              </>
            }
          />
        </div> */}
          </>
        )}
      </div>
      <Filters
        filterValues={filterValues}
        viewRole={"Retailer"}
        handleClick={handleFilterClick}
        searchList={searchList}
        hasPermission= {hasPermission("Create")}
        handleSearchClickclear={(value) => handleFilterSearch(value)}
      />
      <div>
        <div>
          <div>
            <div className="retailer-funnel">
              <div className="card" >
                <div className="card-body funnel-body">
                  <div className="top-bar-funnel">
                    <Dropdown
                      list={["Day", "Month", "Overall"]}
                      identifier={"funnel_type"}
                      handleInput={handleFunnelInput}
                      value={funnelType}
                    />
                    <div className="" style={{ display: "flex", gap: "20px" }}>
                      <div onClick={fetchFiveTopRetailers}>
                      <button style={{ backgroundColor: '#EE3E80',
  color: 'white',
  padding: '10px 20px',
  fontWeight:"bold",
  fontSize: '12px',
  border: '1px solid #EE3E80',
  borderRadius: '5px'}}>Top 5 Retailers</button>
                      
                      </div>
                      <div onClick={onResetData}>
                        <button style={{ backgroundColor: 'white',
  color: '#EE3E80',
  padding: '10px 20px',
  fontSize: '12px',
  border: '1px solid #EE3E80',
  borderRadius: '5px'}}>Reset</button>
                        
                      </div>
                    </div>
                  </div>
                  {!fiveTopRetailersFlag && (
                    <>
                      <div className="row">
                        <div className="col-md-7 col-sm-12 col-xl-7 col-lg-7 ">
                          <div className="card" style={{height:"100%"}}>
                            <div className="card-body">
                            <div
                            style={{
                              display: "flex",
                              // padding:'10px',
                              justifyContent: "space-between",
                             marginTop:"2rem"
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  color: "#5A607F",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                TOTAL
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px":"15px",
                                  marginTop: "2rem",
                                }}
                              >
                                ₹ {funnelData?.total_transaction_amount ?? 0}
                              </div>
                              <div
                               style={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: "12px",
                                marginTop: "2rem",
                                backgroundColor:"#e7dddd",
                                border:"1px solid #e7dddd",
                                padding:'5px 5px',
                                textAlign:"center",
                                borderRadius:"10px"
                              }}
                              >
                                {funnelData?.total_invoice_count} Invoice
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#5A607F",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                PAID
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px":"15px",
                                  marginTop: "2rem",
                                }}
                              >
                                ₹ {funnelData?.total_paid_amount ?? 0}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  marginTop: "2rem",
                                  backgroundColor:"#e7dddd",
                                  border:"1px solid #e7dddd",
                                  padding:'5px 5px',
                                  textAlign:"center",
                                  borderRadius:"10px"
                                }}
                              >
                                  {funnelData?.paid_invoice_count} Invoice
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#5A607F",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                DUE
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px":"15px",
                                  marginTop: "2rem",
                                }}
                              >
                                ₹ {funnelData?.total_due_amount ?? 0}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  marginTop: "2rem",
                                  backgroundColor:"#e7dddd",
                                  border:"1px solid #e7dddd",
                                  padding:'5px 5px',
                                  textAlign:"center",
                                  borderRadius:"10px"
                                }}
                              >
                                {funnelData?.due_invoice_count} Invoice
                              </div>
                            </div>
                          </div>
                            </div>
                          </div>
                          
                        </div>
                        <div className="col-md-5 col-sm-12 col-xl-5 col-lg-5 h-100" style={{marginTop:isMobile ? '1rem':0}}>
                          <div className="card" style={{height:"100%"}}>
                            <div className="card-body h-100">
                            <Chart title={'Retailers Data'} chartData={funnelData} />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="card" style={{marginTop:'1.4rem'}}>
          <div className="card-body">
            <div className="cohort-list">
              {isMobile ? (
                <>
                  <div className="row">
                  <div style={{fontSize:"16px", fontWeight:"bold"}}>
       Total Retailer Count - {totalRecords} 
    </div>
          <div className="col-12">
            {list?.map((data, index) => {
              return (
                <div key={index}>
                  <Listing
                    data={data}
                    viewData={"Retailer"}
                    handlesubmit={handlesubmit}
                  />
                </div>
              );
            })}
          </div>
        </div>
                  <div className="mt-4 d-flex justify-content-center">
                    <Pagination
                      count={Math.ceil(totalRecords / 20)}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePaginationChange}
                    />
                  </div>
                </>
              ) : (
                <>
                  <LeadList
                    rows={list}
                    columns={columns}
                    type={'Retailers'}
                    totalRecords={totalRecords}
                    handlePaginationChange={handlePaginationChange}
                    hasPermission={hasPermission}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12" style={{ marginTop: "1.8rem" }}>
            {list?.map((data, index) => {
              return (
                <div key={index}>
                  <Listing
                    data={data}
                    viewData={"Retailer"}
                    handlesubmit={handlesubmit}
                  />
                </div>
              );
            })}
          </div>
        </div> */}
      </div>

      {/* <div className="mt-4 d-flex justify-content-center">
        <Pagination
          count={Math.ceil(totalRecords / 20)}
          variant="outlined"
          shape="rounded"
          onChange={handlePaginationChange}
        />
      </div> */}
    </div>
  );
};

export default Retailer;
