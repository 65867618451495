export const businessCatagories = [
  "Kirana / Groceries / General Stores",
  "Medical Shop/ Chemist/ Clinic/ Diagnostics",
  "Cosmetics Shop/ Beauty Parlor",
  "Garments Shop / Fabric wholesaler",
  "Boutique",
  "Footwear",
  "Mini Restaurant/ Snack Bar",
  "Mobile and Electronics",
  "Dairy Retail Shops only/Milk Agency (No Livestock)",
  "Paint/Hardware",
  "Retail Furniture Shop",
  "Automobiles",
  "Utensils/Kitchenware Shop",
  "Stationery Shop",
  "Gift Shops",
  "Bicycle Seller",
];
export const categoriesHLP =  [
  "FMCG",
  "Telecom",
  "Electronics",
  "Pharma",
  "Textile",
  "Beauty & Personal Care",
  "Food & Beverage",
  "Automobiles & Parts",
  "Hardware Supplies",
  "Construction",
  "Agriculture & Dairy",
  "Kitchenware",
  "Stationery Printing",
  "Furniture",
  "Footwear",
  "Others"
];
export const paymentOptions = [
  
  "Google Pay",
  "PhonePe",
  "Paytm",
  "Mswipe",
  "PayU",
  "Easytap",
  "Bharat Pe",
  "ICICI Bank",
  "Axis Bank",
  "Bajaj Finance",
  "Union Bank",
  "Others"
];
export const cohortTypes = ["Cashflow", "Transaction"];

export const productType = ["Credit", "Insurance"];
export const productTypeHLP = ["All","Credit","Payment", "Insurance"];
export const desposition = ["Revisit", "Hold to Review", "Not Interested"];

export const revisitDesposition = [
  "Occupied at the moment",
  "	Tech Issue",
  "Insufficient Documents",
  "Partner Not available",
];

export const reviewDesposition = [
  "GSTIN Not available",
  "Out of Occupation",
  "Temporary shop",
  "Below 100 Retailers",
  "	Not a Distributor/Wholesaler",
];

export const notInterestedDesposition = [
  "	Not Interested In Partner Programme",
  "	Come Back later next month",
];

export const inputDisabled = ["QC pending", "Approved", "Rejected", "Re-KYC"];

export const geographyStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
];
export const Fofrole = ["HUNTING"];
export const Centerlist = [
  {
    id: 1,
    bank_branches: "Arth-Bachhrawan",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2023-08-16T09:44:27.509184Z",
  },
  {
    id: 2,
    bank_branches: "Arth-Barabanki",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 3,
    bank_branches: "Arth-Bhiwadi",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 5,
    bank_branches: "Arth-Lucknow",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 6,
    bank_branches: "Arth-Madiyaon",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-11-19T05:49:28.875766Z",
  },
  {
    id: 7,
    bank_branches: "Arth-Pataudi",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 8,
    bank_branches: "Arth-Raebareli",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 9,
    bank_branches: "Arth-Sultanpur",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 10,
    bank_branches: "Arth-Tijara",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-10-29T05:17:37.420103Z",
  },
  {
    id: 11,
    bank_branches: "Arth-Unchahar",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2023-02-10T07:18:08.686098Z",
  },
  {
    id: 18,
    bank_branches: "Arth-Ayodhya",
    create_ts: "2022-10-29T00:00:00Z",
    update_ts: "2022-11-15T09:49:09.880114Z",
  },
  {
    id: 19,
    bank_branches: "Arth-Udaipur",
    create_ts: "2023-08-16T00:00:00Z",
    update_ts: "2023-08-16T09:44:17.786074Z",
  },
  {
    id: 20,
    bank_branches: "Arth-Nagpur",
    create_ts: "2023-08-16T00:00:00Z",
    update_ts: "2023-08-16T09:44:08.096974Z",
  },
  {
    id: 21,
    bank_branches: "Arth-Jaipur-2",
    create_ts: "2023-08-16T00:00:00Z",
    update_ts: "2023-09-01T06:16:55.676995Z",
  },
  {
    id: 22,
    bank_branches: "Arth-Test 1",
    create_ts: "2023-10-29T09:37:12.507848Z",
    update_ts: "2023-10-29T09:37:12.507866Z",
  },
  {
    id: 23,
    bank_branches: "Arth-Test 2",
    create_ts: "2023-10-29T09:39:27.279235Z",
    update_ts: "2023-10-29T09:39:27.279302Z",
  },
  {
    id: 24,
    bank_branches: "Arth-Test 3",
    create_ts: "2023-10-29T09:41:35.271974Z",
    update_ts: "2023-10-29T09:41:35.272014Z",
  },
  {
    id: 25,
    bank_branches: "Arth-Test 1",
    create_ts: "2023-10-30T10:57:09.778195Z",
    update_ts: "2023-10-30T10:57:09.778224Z",
  },
  {
    id: 26,
    bank_branches: "Arth-Test 14",
    create_ts: "2023-10-30T10:59:38.909262Z",
    update_ts: "2023-10-30T10:59:38.909334Z",
  },
  {
    id: 27,
    bank_branches: "Arth-Test 14",
    create_ts: "2023-10-30T11:00:07.869644Z",
    update_ts: "2023-10-30T11:00:07.869661Z",
  },
  {
    id: 28,
    bank_branches: "Arth-Test 14",
    create_ts: "2023-10-30T11:00:25.357488Z",
    update_ts: "2023-10-30T11:00:25.357551Z",
  },
  {
    id: 29,
    bank_branches: "Arth-Test 14",
    create_ts: "2023-10-30T11:01:04.058176Z",
    update_ts: "2023-10-30T11:01:04.058277Z",
  },
  {
    id: 30,
    bank_branches: "Arth-Test 145",
    create_ts: "2023-10-30T11:01:24.687199Z",
    update_ts: "2023-10-30T11:01:24.687291Z",
  },
  {
    id: 31,
    bank_branches: "Arth-Test 146",
    create_ts: "2023-10-30T11:17:12.649325Z",
    update_ts: "2023-10-30T11:17:12.649390Z",
  },
  {
    id: 32,
    bank_branches: "Arth-Test 148",
    create_ts: "2023-10-30T11:29:23.926430Z",
    update_ts: "2023-10-30T11:29:23.926457Z",
  },
  {
    id: 33,
    bank_branches: "Arth-Test 150",
    create_ts: "2023-10-30T11:33:37.931741Z",
    update_ts: "2023-10-30T11:33:37.931828Z",
  },
  {
    id: 34,
    bank_branches: "Arth-''",
    create_ts: "2023-11-01T06:45:20.708815Z",
    update_ts: "2023-11-01T06:45:20.708886Z",
  },
  {
    id: 35,
    bank_branches: "Arth-Hi vhbrvhb",
    create_ts: "2023-11-01T06:54:42.894235Z",
    update_ts: "2023-11-01T06:54:42.894284Z",
  },
  {
    id: 38,
    bank_branches: "Arth-Hi vhbrvhb 2",
    create_ts: "2023-11-01T07:37:07.978978Z",
    update_ts: "2023-11-01T07:37:07.979057Z",
  },
  {
    id: 39,
    bank_branches: "Arth-Hi vhbrvhb 3",
    create_ts: "2023-11-01T07:41:00.449242Z",
    update_ts: "2023-11-01T07:41:00.449574Z",
  },
  {
    id: 40,
    bank_branches: "Arth-Test future self",
    create_ts: "2023-11-20T04:49:33.797682Z",
    update_ts: "2023-11-20T04:49:33.797703Z",
  },
  {
    id: 41,
    bank_branches: "Arth-Demo01",
    create_ts: "2023-11-20T10:39:36.997454Z",
    update_ts: "2023-11-20T10:39:36.997474Z",
  },
  {
    id: 42,
    bank_branches: "Arth-trial testing 2",
    create_ts: "2023-11-20T12:08:24.334649Z",
    update_ts: "2023-11-20T12:08:30.876/898Z",
  },
  {
    id: 43,
    bank_branches: "Arth-Kanpur",
    create_ts: "2024-05-29T05:37:42.850142Z",
    update_ts: "2024-05-29T05:37:42.850163Z",
  },
];
export const ShopType = ["Owned", "Rented"];
export const bankList = [
  "Axis Bank",
  "Bandhan Bank",
  "Bank of Baroda",
  "Bank Of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "City Union BankCAF",
  "Federal Bank",
  "HDFC Bank",
  "ICICI Bank",
  "IDBI Bank",
  "IDFC First Bank",
  "Indian Bank",
  "Indian Overseas Bank",
  "IndusInd Bank",
  "Kotak Mahindra Bank",
  "Punjab and Sindh Bank",
  "RBL Bank",
  "State Bank of India",
  "UCO Bank",
  "Union Bank of India",
  "Yes Bank",
  "Others",
  "None",
];
export const paymentMethod = [
  "Phonepe",
  "Google Pay",
  "Bharat Pay",
  "Paytm",
  "Others",
  "None",
];
export const leadproductType = [
  {
    id: "small_business_loan_product",
    name: "Small Business Loan",
    created_at: "2024-06-17T06:59:33.606804Z",
    updated_at: "2024-06-17T06:59:33.606804Z",
  },
  {
    id: "high_business_loan_product",
    name: "High Business Loan",
    created_at: "2024-06-17T06:59:33.606804Z",
    updated_at: "2024-06-17T06:59:33.606804Z",
  },

  {
    id: "insurance_product",
    name: "Insurance (Health/Life)",
    created_at: "2024-06-17T06:59:33.606804Z",
    updated_at: "2024-06-17T06:59:33.606804Z",
  },
  {
    id: "arth_paymint_product",
    name: "ARTH Paymint",
    created_at: "2024-06-17T06:59:33.606804Z",
    updated_at: "2024-06-17T06:59:33.606804Z",
  },
  {
    id: "not_interested_product",
    name: "Not Interested",
    created_at: "2024-06-17T06:59:33.606804Z",
    updated_at: "2024-06-17T06:59:33.606804Z",
  },
  {
    id: "loan_against_property_product",
    name: "Loan Against Property/Housing loan",
    created_at: "2024-06-17T06:59:33.606804Z",
    updated_at: "2024-06-17T06:59:33.606804Z",
  },
];

export const OccupationCategory = [
  "Kirana/Groceries/General Store",
  "Electronics, E-commerce, Mobile Accessories, Telecom",
  "Pharmacy/Medical",
  "Salon/Cosmetics/Beauty Parlor/Fitness",
  "Apparels/Clothing/Boutique/Garments",
  "Boutique and tailoring",
  "Restaurant/Food",
  "Others",
];

export const squareFootageRanges = [
  "<100 Sq. Ft.",
  "100-250 Sq. Ft.",
  "250-500 Sq. Ft.",
  "500-1000 Sq. Ft.",
  "1000-2000 Sq. Ft.",
  ">2000 Sq. Ft.",
];
export const centerNames = [
  "Ayodhya-Manas Nagar",
  "Ayodhya-Hansapur",
  "Ayodhya-Maushiwala",
  "Ayodhya-Paigapur",
  "Ayodhya-Rikabganj",
  "Ayodhya-Sahadatganj",
  "Ayodhya-Abboo Sarai",
  "Ayodhya-Fatehganj",
  "Ayodhya-RTO Office",
  "Ayodhya-Rampur",
  "Ayodhya-Darshan Nagar",
  "Ayodhya-Chowk Ayodhaya Road",
  "Ayodhya-Tedhi Bazar",
  "Ayodhya-Achari Ka Sagra",
  "Ayodhya-Ayodhya",
  "Ayodhya-Banbirpur",
  "Ayodhya-Ghatam Pur",
  "Ayodhya-Jagdishpur",
  "Ayodhya-Ballipur",
  "Ayodhya-Tajpur Kodara",
  "Ayodhya-Devkali",
  "Ayodhya-Katra",
  "Ayodhya-Kot Saray",
  "Ayodhya-Faizabad Cant",
  "Ayodhya-Gopalpur",
  "Ayodhya-Bichhiya",
  "Ayodhya-Rani Bajar",
  "Ayodhya-Ramghat",
  "Ayodhya-Wazeerganj",
  "Ayodhya-Paliagoa",
  "Ayodhya-Bhadarsa",
  "Ayodhya-Bharatkund, Bhadarsha",
  "Ayodhya-Pura Baazar",
  "Ayodhya-Bikapur",
  "Ayodhya-Sohawal",
  "Ayodhya-Sanjayganj Bazar",
  "Ayodhya-Vikramjot",
  "Ayodhya-Rampur Bhagan",
  "Ayodhya-Maya Baazar",
  "Ayodhya-Nawabganj",
  "Ayodhya-Chhawani",
  "Ayodhya-Tarabganj",
  "Sultanpur-Vallipur Bazar",
  "Sultanpur-Kasba Sultanpur",
  "Sultanpur-Majar Ganj",
  "Sultanpur-Ratan Pur",
  "Sultanpur-Ghabadiya",
  "Sultanpur-Vinowapuri",
  "Sultanpur-Lohramau",
  "Sultanpur-Pyarepatti",
  "Sultanpur-Shastri Nagar",
  "Sultanpur-Vivek Nagar",
  "Sultanpur-Gorabarik",
  "Sultanpur-Amhat",
  "Sultanpur-Saifulla Ganj",
  "Sultanpur-Payagipur",
  "Sultanpur-Murlinagar",
  "Sultanpur-Tedhui",
  "Sultanpur-Amhat Chauraha",
  "Sultanpur-Denva",
  "Sultanpur-Bankepur",
  "Sultanpur-Ahimane",
  "Sultanpur-Katka Khan Pur",
  "Sultanpur-Rampur Hanuman Ganj",
  "Sultanpur-Dwarikaganj",
  "Sultanpur-Bandhuakala",
  "Sultanpur-Dhammaur",
  "Bhiwadi-Nagliya",
  "Bhiwadi-Central Market",
  "Bhiwadi-Vikash Nagar",
  "Bhiwadi-Karan Kunj",
  "Bhiwadi-Sector 4, UIT",
  "Bhiwadi-Mansa Chowk, UIT",
  "Bhiwadi-Phool Bhag",
  "Bhiwadi-Alampur, UIT",
  "Bhiwadi-Block B, UIT",
  "Bhiwadi-Sector 2A, UIT",
  "Bhiwadi-Sector 3, UIT",
  "Bhiwadi-Saidpur",
  "Bhiwadi-Sector 5, UIT",
  "Bhiwadi-Sector 6, UIT",
  "Bhiwadi-Akera",
  "Bhiwadi-Santhalka",
  "Bhiwadi-Harchandpur",
  "Bhiwadi-Sector 9, UIT",
  "Bhiwadi-Honda Chowk",
  "Bhiwadi-Dharuhera",
  "Bhiwadi-Garhi Alawalpur",
  "Bhiwadi-Suraj Cinema",
  "Bhiwadi-Alwar By Pass Market",
  "Bhiwadi-Khori Kalan",
  "Bhiwadi-Phase 3, RIICO",
  "Bhiwadi-Khijuriwas",
  "Bhiwadi-Kapriwas",
  "Bhiwadi-Bilaheri",
  "Bhiwadi-Matila",
  "Bhiwadi-Malpura",
  "Bhiwadi-Tauru",
  "Bhiwadi-Chaupanki",
  "Bhiwadi-Tapukara",
  "Bhiwadi-Bilaspur Market",
  "Pataudi-Haileymandi",
  "Pataudi-Ward Number 8, Haileymandi",
  "Pataudi-Mirzapur",
  "Pataudi-Bus Stand, Pataudi",
  "Pataudi-Nohata Chowk",
  "Pataudi-Chhota Bazar, Pataudi",
  "Pataudi-Rampur",
  "Pataudi-Milakpur",
  "Pataudi-Luhari",
  "Pataudi-Janaula",
  "Pataudi-Uncha Majra",
  "Pataudi-Narhera",
  "Pataudi-Jori",
  "Pataudi-Khandewla",
  "Pataudi-Jatola",
  "Pataudi-Mehchana",
  "Pataudi-Sanpka",
  "Pataudi-Basunda",
  "Pataudi-Bhora Kalan",
  "Pataudi-Farukh Nagar",
  "Pataudi-Kulana",
  "Pataudi-Panchgaon",
  "Delhi-Patel Nagar",
  "Delhi-Delhi Cant",
  "Delhi-Inderpuri",
  "Delhi-Kirti Nagar",
  "Delhi-Tilak Nagar",
  "Delhi-Moti Bagh",
  "Delhi-RK Puram",
  "Delhi-Sarojini Nagar",
  "Delhi-Punjabi Bagh",
  "Delhi-Tagore Garden",
  "Delhi-Naraina",
  "Delhi-Safdarjung Enclave",
  "Delhi-Pitampura",
  "Delhi-Keshav Puram",
  "Delhi-Mahipalpur",
  "Delhi-Mahavir Enclave",
  "Delhi-Dabri",
  "Delhi-Ashok Vihar",
  "Delhi-Vasant Vihar",
  "Delhi-Janak Puri",
  "Delhi-Uttam Nagar",
  "Delhi-Rajinder Nagar",
  "Delhi-Paschim Vihar",
  "Delhi-Hari Nagar",
  "Delhi-RK Puram",
  "Delhi-Qutub Vihar",
  "Delhi-Dwarka",
  "Delhi-Dwarka, Sec-8",
  "Delhi-Dwarka, Sec-14",
  "Delhi-Dwarka, Sec-4",
  "Delhi-Paschim Vihar",
  "Delhi-Shalimar Bagh",
  "Delhi-Sadar Bazaar, Delhi Cant",
  "Delhi-Vikas Puri",
  "Delhi-Fateh Nagar",
  "Delhi-Vishnu Garden",
  "Delhi-Khayala",
  "Delhi-Chander Vihar",
  "Delhi-Raghubir Nagar",
  "Delhi-Subhash Nagar",
  "Delhi-Rajouri Garden",
  "Delhi-Delhi Gate, Najafgarh",
  "Delhi-Vijay Enclave",
  "Delhi-Sadh Nagar",
  "Delhi-Mangla Puri",
  "Delhi-Kailash Puri",
  "Delhi-Sagar Pur",
  "Delhi-Nangal Raya",
  "Delhi-Possangipur, Janak Puri",
  "Delhi-Shiv Vihar, Janak Puri",
  "Delhi-Lajwanti Garden",
  "Delhi-Bindapur, Uttam Nagar",
  "Delhi-Milap Nagar, Uttam Nagar",
  "Delhi-Madhu Vihar, Uttam Nagar",
  "Delhi-Om Vihar, Uttam Nagar",
  "Delhi-Mohan Garden, Uttam Nagar",
  "Delhi-Rama Park, Uttam Nagar",
  "Delhi-Nawada, Uttam Nagar",
  "Delhi-Sewak Park, Uttam Nagar",
  "Delhi-Vikas Nagar, Uttam Nagar",
  "Delhi-Madipur",
  "Delhi-Jwala Heri, Paschim Vihar",
  "Delhi-Maya Puri",
  "Delhi-Palam",
  "Delhi-Dwarka, Sec-15",
  "Delhi-Peera Garhi",
  "Madiyaon-Yadav Market",
  "Madiyaon-Raitha Road",
  "Madiyaon-Jankipuram Ext",
  "Madiyaon-Bakshi Ka Talab",
  "Madiyaon-Engineering College Road",
  "Madiyaon-Jankipuram 60Ft Road",
  "Madiyaon-Aliganj",
  "Madiyaon-Tedhi Pulia",
  "Madiyaon-Adil Nagar",
  "Madiyaon-Faizullaganj",
  "Madiyaon-Puraniya",
  "Madiyaon-Hayat Nagar",
  "Madiyaon-Kalyanpur",
  "Madiyaon-Kapurthala",
  "Madiyaon-Vikas Nagar",
  "Madiyaon-Adarsh Nagar",
  "Madiyaon-Kalyanpur West",
  "Madiyaon-Kalyanpur East",
  "Madiyaon-Gaurabhagh",
  "Madiyaon-Dubagga",
  "Madiyaon-Mahanagar",
  "Madiyaon-Hari Nagar",
  "Madiyaon-Daliganj",
  "Madiyaon-Picnic Spot Road",
  "Madiyaon-Assi Road, Bakshi Ka Talab",
  "Madiyaon-Badshahnagar",
  "Madiyaon-Balaganj",
  "Madiyaon-Daulataganj",
  "Madiyaon-Nishatganj",
  "Madiyaon-Kursi Road",
  "Madiyaon-Panna Lal Road",
  "Madiyaon-Faridi Nagar",
  "Madiyaon-Indira Nagar",
  "Madiyaon-Munshi Pulia",
  "Madiyaon-Bhootnath Market",
  "Madiyaon-Takrohi",
  "Madiyaon-Aminabad",
  "Madiyaon-Hazratganj",
  "Madiyaon-Amrai Gaon",
  "Madiyaon-Charbagh",
  "Madiyaon-Chinhat",
  "Madiyaon-Gomti Nagar",
  "Madiyaon-Matiyari",
  "Madiyaon-Gomtinagar Extension",
  "Madiyaon-Bangla Bazar",
  "Madiyaon-Sadar Bazaar",
  "Madiyaon-Telibagh",
  "Madiyaon-Nilmatha",
  "Madiyaon-Alambagh",
  "Madiyaon-Ashiyana",
  "Madiyaon-Vrindavan Colony",
  "Madiyaon-Mati",
  "Bachhrawan-Bachrawan",
  "Bachhrawan-Kundanganj",
  "Bachhrawan-Tilenda",
  "Raebareli-Bharat Ganj",
  "Raebareli-Feroz Gandhi Market",
  "Raebareli-Gora Bazar",
  "Raebareli-Bargad Chauraha",
  "Raebareli-Krishna Nagar",
  "Raebareli-Nahar Kothi",
  "Raebareli-Munshiganj",
  "Raebareli-Rana Nagar",
  "Raebareli-Jagdishpur",
  "Raebareli-Habibullanagar",
  "Raebareli-Ratapur",
  "Raebareli-Ratapur-Khaspari",
  "Raebareli-Alimiya Market (Sabji Mandi)",
  "Raebareli-Bus Stop",
  "Raebareli-Degree College Chauraha",
  "Raebareli-Ghanta Ghar",
  "Raebareli-Kaiparganj",
  "Raebareli-Madhuban",
  "Raebareli-Police Line",
  "Raebareli-Prashad Market",
  "Raebareli-Shri Shyam Market",
  "Raebareli-Super Market",
  "Raebareli-Rahi",
  "Raebareli-PNT Colony",
  "Raebareli-Dariyapur",
  "Raebareli-Dareeba",
  "Raebareli-Ahiya Raipur",
  "Raebareli-Kuchariya",
  "Raebareli-Bhadokhar-Suchi",
  "Raebareli-Hardaspur",
  "Raebareli-Ganga Ganj",
  "Raebareli-Kanpur Road ",
  "Raebareli-Kachaunda _Nanakari",
  "Raebareli-Kahron ka Adda",
  "Raebareli-Malikmau",
  "Raebareli-ITI Balapur",
  "Raebareli-Pragtipuram",
  "Raebareli-Kila bazar",
  "Raebareli-Harchandpur",
  "Raebareli-Gangaganj",
  "Raebareli-Odaree",
  "Raebareli-Jais",
  "Raebareli-Thulwasa",
  "Raebareli-Maharajganj",
  "Raebareli-Amawan",
  "Raebareli-Ratapur-Khaspari",
  "Raebareli-Bhadokhar-Suchi",
  "Raebareli-Kanpur Road",
  "Raebareli-Bhadokhar",
  "Raebareli-Gangaganj",
  "Raebareli-Fursatganj",
  "Raebareli-Mejorganj",
  "Raebareli-Amanwa",
  "Raebareli-Maharajganj",
  "Raebareli-Ataura Buzurg",
  "Raebareli-Akelwa Chauraha",
  "Raebareli-Sundarganj Chauraha",
  "Raebareli-Deeh Market",
  "Raebareli-Ghorwara",
  "Raebareli-Alawalpur",
  "Raebareli-Suchi",
  "Raebareli-Odaree",
  "Raebareli-Aihar",
  "Raebareli-Jais",
  "Raebareli-Uttarpara",
  "Raebareli-Shankarganj",
  "Raebareli-Murai Bagh",
  "Unchahar-Babuganj",
  "Unchahar-Babuganj Road",
  "Unchahar-Unchahar Market",
  "Unchahar-Unchahar Bus Stand",
  "Unchahar-NTPC",
  "Unchahar-Unchahar Chowk",
  "Unchahar-Kharauli",
  "Unchahar-Sawaya Tiraha",
  "Unchahar-Madari Ganj",
  "Unchahar-Arkha",
  "Unchahar-Kand Rawan",
  "Unchahar-Bikar Garh",
  "Unchahar-Umran",
  "Unchahar-Daulatpur",
  "Unchahar-Jamunapur",
  "Unchahar-Mateen Ganj",
  "Unchahar-Laxmi Ganj",
  "Unchahar-Gokana Ghat",
  "Unchahar-Itaura Buzurg",
  "Bhiwadi-Rewari",
  "Bhiwadi-Tijara",
  "Bachhrawan-Hardoi",
  "Bachhrawan-Karanpur",
  "Bachhrawan-Bahuda Kala",
  "Bachhrawan-Bhawani Garh",
  "Bachhrawan-Johwa Sarki",
  "Bachhrawan-Radhopur",
  "Bachhrawan-Bhagwan Pur",
  "Bachhrawan-Bairisal Pur",
  "Bachhrawan-Hilauli",
  "Bachhrawan-Krishnapur",
  "Bachhrawan-Nigoha",
  "Bachhrawan-Karan Pur",
  "Bachhrawan-Shiv Garh",
  "Bachhrawan-Rampur Garhi",
  "Bachhrawan-Sandana",
  "Bachhrawan-Jabarauli",
  "Bachhrawan-Gurbaxganj",
  "Bachhrawan-Gambhipur",
  "Bachhrawan-Sataon",
  "Bachhrawan-Bindaua",
  "Bachhrawan-Mohanlal Ganj",
  "Bachhrawan-Bachrawan Main Market",
  "Bachhrawan-Raja Mau",
  "Bachhrawan-Chhotkwa Khera",
  "Bachhrawan-Johwa Sharki",
  "Bachhrawan-Pachmim Gaon",
  "Bachhrawan-Samaspur Halor",
  "Bachhrawan-Mohanlalganj",
  "Unchahar-Sabeespur",
  "Unchahar-Pampapur",
  "Unchahar-Usraina",
  "Unchahar-Binda Ganj",
  "Unchahar-Pariyawan",
  "Unchahar-Janwamau",
  "Unchahar-Jhokwara",
  "Unchahar-Salon",
  "Unchahar-Jagatpur",
  "Unchahar-Jagatpur",
  "Barabanki-Nehru Nagar",
  "Barabanki-Ghosiyana",
  "Barabanki-Satyapremi Nagar",
  "Barabanki-Ayodhya Nagar",
  "Barabanki-Nawabganj",
  "Barabanki-Ramna",
  "Barabanki-Shivaji Puram",
  "Barabanki-Palhari",
  "Barabanki-Banki",
  "Barabanki-Lakhpedabagh",
  "Barabanki-Obaree",
  "Barabanki-Gandhi Nagar",
  "Barabanki-Jaswant Nagar",
  "Barabanki-Faizullaganj",
  "Barabanki-Peerbatawan",
  "Barabanki-Dhakauli",
  "Barabanki-Sultanpur",
  "Barabanki-Bahadur Pur",
  "Barabanki-Barail",
  "Barabanki-Ganga Vihar Colony",
  "Barabanki-Nehru Nagar, Barabanki",
  "Barabanki-Ghosiyana, Barabanki",
  "Barabanki-Satyapremi Nagar, Barabanki",
  "Barabanki-Ayodhya Nagar, Barabanki",
  "Barabanki-Nawabganj, Barabanki",
  "Barabanki-Ramna, Barabanki",
  "Barabanki-Shivaji Puram, Barabanki",
  "Barabanki-Palhari, Barabanki",
  "Barabanki-Banki, Barabanki",
  "Barabanki-Lakhpedabagh, Barabanki",
  "Barabanki-Obaree, Barabanki",
  "Barabanki-Gandhi Nagar, Barabanki",
  "Barabanki-Jaswant Nagar, Barabanki",
  "Barabanki-Faizullaganj, Barabanki",
  "Barabanki-Peerbatawan, Barabanki",
  "Barabanki-Dhakauli, Barabanki",
  "Barabanki-Sultanpur, Barabanki",
  "Barabanki-Bahadur Pur, Barabanki",
  "Barabanki-Barail, Barabanki",
  "Barabanki-Ganga Vihar Colony, Barabanki",
  "Jaipur-sanganer",
  "Jaipur-Mahima nagar",
  "Jaipur-Gapatupra",
  "Jaipur-Mansarovar",
  "Jaipur-SFS Mansarovar",
  "Ayodhya-Other",
  "Bacchrawan-Other",
  "Bhiwadi-Other",
  "Delhi-Other",
  "Jaipur-Other",
  "Lucknow-Other",
  "Pataudi-Other",
  "Raebareli-Other",
  "Sultanpur-Other",
  "Unchahar-Other",
];
export const taskAssignmentCaf = [
  "Visit Completed - Interested",
  "Revisit",
  "Hold to Review",
  "Not Interested",
  "Tech Issue",
  // "Business Location not found"
];
export const taskAssignmentRevisitList = [
  "Shop Closed",
  "Tech Issue",
  "Customer Not available",
  "Shop not in location",
  //"Insufficient Documents"
];
export const taskAssignmentNotEligibleList = [
  "Shop Permanentaly closed",
  "Out of Occupation",
  "Temporary shop",
  "Doubtful customer",
  "Already Active loan in family",
  "Business Location not found",
];
export const taskAssignmentNotInterestedList = [
  "Not Required at All",
  "High Rate of Interest",
  "High loan amount required",
  "Come back later next month",
];
export const taskAssignmentTechIssueList = [
  "OTP Issue",
  "App not working",
  "Unable to verify KYC",
  "Enach Issue (Capture Paper Nach)",
];
export const taskassignmentInterestsubtask = ['Secured Loan',
'Unsecured - Small Business Loan',
'Unsecured - Medium Business Loan',
'Unsecured - High Business Loan']
export const taskAssignmentPQList = [
  "Promise to pay",
  "Customer not available",
  "Already Paid",
  "Dispute Case",
  "Genuine non-payment reason",
  "Refuse to pay",
  "Payment Collected",
  "Unpaid Scenario",
  "Not Contactable",
];
export const taskAssignmentCollected = [
  "Partial Amount Collected",
  "EMI Amount Collected",
  "No Amount Collected",
];
export const taskAssignmentCustomernotavailable = [
  "Customer not at shop",
  "Shop Closed",
];
export const taskAssignmentAlreadyPaid = [
  "OD amount already paid",
  "Loan Closed",
];
export const taskAssignmentDisputeCase = [
  "Customer complaint",
  "Insurance claim",
  "Late fees dispute",
  "Loan amount dispute",
  "Outstanding dues dispute",
  "Penalty Dispute",
];
export const familyMember = ["Immediate Family", "Co-borrower", "Borrower"];
export const healthConcern = ["Health Issue", "Hospitalisation"];
export const taskAssignmentCustomercomplaint = [
  "Misbehaviour",
  "Wrong communication",
];
export const taskAssignmentWrongcommunication = [
  "Health",
  "Life",
  "Accidental",
];
export const taskAssignmentnonpayment = [
  "Demise in family",
  "Family/customer medical issue",
  "Shut down of Business",
];
export const taskAssignmentShutdown = [
  "Doing Nothing",
  "In a Job",
  "Working as Labour",
  "Starting new business",
];
export const taskAssignmentRefusetopay = [
  "Deny to pay",
  "Intention Issue",
  "Local Influence/Negative Locality",
];
export const taskAssignmentUnpaidScenerio = [
  "Customer Death",
  "Customer Absconded",
];
export const MasterTasksList = [
  "New_HLP_Visit",
  "Existing_HLP_Visit",
  "HLP_CAF",
  "HTL_CAF",
  "Repeat_Top_Up",
  "Repeat_Closed",
  "Portfolio_Quality_Visit",
  "STL_NTL_CAF",
  "ADDITIONAL_TASK",
  "COLLECTION_TASK",
  "Business_Prime",
  "HLP_Hot_Lead",
];
export const NewMasterTasksList = [
  "Portfolio_Quality_Visit",
  "PQ - Collections",
  "Repeat Loan Visit",
  "Loan Application",
  "Field Verification",
  "Partner Engagement",
  "HLP_Hot_Lead",
  "New_HLP_Visit",
  "Existing_HLP_Visit",
  "HLP_CAF",
  "HTL_CAF",
  "Repeat_Top_Up",
  "Repeat_Closed",
  "STL_NTL_CAF",
  "ADDITIONAL_TASK",
  "COLLECTION_TASK",
  "Business_Prime",
];
export const pqSubtask = [
  'OD - 01 to 10',
  'OD - 11 to 30',
  'OD - 31 to 60',
  'OD - 61 to 90',
  'PAR - 91 to 180',
  'PAR - 181 to 365',
  'PAR - 366 to 720',
  'NC Customer',
  'Red Category',
  'Non Starter',
  'Nach Bounce',
  'High Risk',
  'High Priority',
  'FLDG/High Priority',
  'Magic QR Not Pasted',
]
export const pqSubtaskList = [
  'Pre Due - NC Customer',
  'Pre Due - Red Category',
  'OD - 01 to 10',
  'OD - 11 to 30',
  'OD - 31 to 60',
  'High Risk - 61 to 90',
  'High Risk - 91 to 180',
  'High Risk - 181 to 365',
  'OD - 61 to 90',
  'PAR - 91 to 180',
  'PAR - 181 to 365',
  'PAR - 366 to 720',
  'Non Starter',
  'Magic QR Not Pasted',
]
export const repeat_Visitsubtask = [
  'Unsecured - Repeat Loan',
]
export const Loan_Visitsubtask = [
  'Secured Loan','Unsecured - Small Business Loan','Unsecured - Medium Business Loan','Unsecured - High Business Loan','Secured or Unsecured Loan'
]
export const BusinessPrimesubtask = [
  'Merchant Onboarding','Box & Device delivery','Box & Device Repossession'
]
export const FieldVerificationsubtask = [
  "PD Visit","Inhouse Case FV","Partner Case FV","Loan Sanction Visit"
]
export const FieldVerificationTaskDisposition = [
  "Revisit","Met",
]
export const HLP_Visitsubtask = [
  "New HLP Visit","Existing HLP Visit"
]
export const taskAssignmentmax_new_HLP_visit = [
  "Successfully Onboarded",
  "Revisit",
  "Hold to Review",
  "Not Interested",
];
export const max_new_HLP_visit_Revisit = [
  "Shop Closed",
  "Tech Issue",
  "Partner Not available",
  "Shop not in location",
  //"Insufficient Documents"
];
export const max_new_HLP_visit_NotEligible = [
  "Shop Permanentaly closed",
  "Out of Occupation",
  "Temporary shop",
  "Below 100 Retailers",
  "Not a Distributor/Wholesaler",
  "Business Location not found",
  //"Insufficient Documents",
];
export const max_new_HLP_visit_NotInterested = [
  "Not Interested In Partner Programme",
  "Busy-Do Not have time",
  "Come back later next month",
];
export const taskAssignmentmax_existing_hlp_visit = [
  "Revisit",
  "Partner Available",
  // "Share 3 Merchant Details",
  // "Explained Invoicing App",
  // "Number of Retailers",
  // "HLP Trained for HOT lead & Invoicing",
  // "Reference of New HLP"
];
export const max_existing_hlp_visitRevisit = [
  "Shop Closed",
  "Partner Not available",
];

export const additionalTasksList = [
  "Visit Completed - Interested",
  "Revisit",
  "Hold to Review",
  "Not Interested",
  // "Others"
];

export const additionalTaskListRevisit = [
  "Shop Closed",
  "Tech Issue",
  "Customer Not available",
  "Shop not in location",
  //"Insufficient Documents"
];
export const additionalTaskListNotEligible = [
  "Shop Permanentaly closed",
  "Out of Occupation",
  "Temporary shop",
  "Doubtful customer",
  "Already Active loan in family",
  "Business Location not found",
];
export const additionalTaskListNotInterested = [
  "Not Required at All",
  "High Rate of Interest",
  "High loan amount required",
  "Come back later next month",
];
export const collectionTaskList = [
  "Payment Collected",
  "Unpaid Scenario",
  "Not Contactable",
  "Dispute Case",
  // "Others"
];
export const collectionTaskListUnpaidScenerio = [
  "Customer Death",
  "Customer Absconded",
  "Demise in family",
  "Family/customer medical issue",
  "Shut down of Business",
  "Customer not at shop",
  "Shop Closed",
  "Promise to pay",
];
export const collectionTaskListUnpaidScenerioShutdown = [
  "Doing Nothing",
  "In a Job",
  "Working as Labour",
  "Starting new business",
];

export const collectionTaskListCustomercomplaint = [
  "Misbehaviour",
  "Wrong communication",
];
export const collectionTaskListCustomerInsuranceclaim = [
  "Health",
  "Life",
  "Accidental",
];
export const collectionTaskListDisputeCase = [
  "Customer complaint",
  "Insurance claim",
  "Late fees dispute",
  "Loan amount dispute",
  "Outstanding dues dispute",
  "Penalty Dispute",
];

export const partnerStatusComplete = [
  "Loan Acceptance Pending",
  "Under Review",
  "Appraisal In Progress",
  "Rejected",
  "re_kyc",
  "Disbursed",
  "fully_repaid",
  "cancelled",
];
export const partnerStatusInprogress = [
  "caf_in_progress",
  "not_eligible",
  "enach_pending",
];
export const qcRemarksdatapoor = [
  "Business Environment",
  "Business Name Unavailable",
  "Business Permanently Closed in Map",
  "Business Unavailable on Maps",
  "Low Stock or Inventory",
  "Out of Center Region",
  "Out of Occupation",
  "Shop Photo Unavailable",
  "Shop Size below 150 Sqft",
  "Temporary Setup",
  "Temporary Structure or Shop",
  "Negative Pin Code",
  "Map Vintage",
];
export const qcRemarksdata = [
  "Business Environment",
  "Business Name Unavailable",
  "Business Permanently Closed in Map",
  "Business Unavailable on Maps",
  "Low Stock or Inventory",
  "Out of Center Region",
  "Out of Occupation",
  "Shop Photo Unavailable",
  "Shop Size below 150 Sqft",
  "Temporary Setup",
  "Temporary Structure or Shop",
];

export const excellentBusinessdata = ["Brand Managed", "Franchise"];

export const STLDisposition = [
  "Interested",
  "Re-Visit",
  "Required_Later",
  "Not Eligible",
  "Hold to Review",
  "Not Interested",
];
export const RepeatDisposition = [
  "Interested",
  "Re-Visit",
  "Required_Later",
  "Not Eligible",
  "Hold to Review",
  "Not Interested",
];
export const ReVisitRepeatDisposition = [
  "Customer Unavailable",
  "Shop Closed",
  "Shop Not Found",
  "Customer Busy in Business",
  "Customer Busy with Family",
  "Customer Out of Town",
  "Interested - Follow Up for Appointment",
];
export const DispositionListFieldVerificationRevisit = [
  "Shop Closed",
  "Customer Not Available",
  "Shop Not in location",
];
export const Not_EligibleRepeatDisposition = [
  "Active Loan in Immediate Family with ARTH",
  "Active Loan with ARTH",
  "Eligibility Fail in Loan Application"
];
export const Not_EligibleRepeatDispositionSecureLoan = [
  "Active Loan in Immediate Family with ARTH",
  "Active Loan with ARTH",
  "Eligibility Fail in Loan Application",
  "Property is Rented",
  "Property Documents not Available"
];
export const HoldtoReviewRepeatDisposition = [
  "Age Norm Not Met",
  "Business Closed - New Business Setup",
  "Business Closed - Salaried",
  "Business Closed - Unemployed",
  "Business Not Owned by Customer",
  "Co-Borrower Not Eligible",
  "Co-Borrower Unavailability",
  "High Loan Amount Required",
  "High Loan Tenure Required",
  "Incomplete Documents",
  "Low Income Identified",
  "Low Stock Identified",
  "Negative Pin Code or Area",
  "Not Directly Involved in Business",
  "Out Of Center Region",
  "Out of Occupation",
  "Temporary Business Setup",
  "Temporary Structure or Shop",
  "Franchise Model",
];
export const HoldtoReviewRepeatDispositionSecureLoan = [
  "Age Norm Not Met",
  "Business Closed - New Business Setup",
  "Business Closed - Salaried",
  "Business Closed - Unemployed",
  "Business Not Owned by Customer",
  "Co-Borrower Not Eligible",
  "Co-Borrower Unavailability",
  "High Loan Amount Required",
  "High Loan Tenure Required",
  "Incomplete Documents",
  "Low Income Identified",
  "Low Stock Identified",
  "Negative Pin Code or Area",
  "Not Directly Involved in Business",
  "Out Of Center Region",
  "Out of Occupation",
  "Temporary Business Setup",
  "Temporary Structure or Shop",
  "Franchise Model",
  "Property is Rented",
  "Property Documents not Available"
];
export const Not_InterestedRepeatDisposition = [
  "Active Loan from Other Entity",
  "High Processing Fee",
  "High Rate of Interest",
  "Loan Not Required",
];
export const categories = [
  "Agri Equipments",
  "Livestock Feed & Fodder",
  "Nursery & Gardening",
  "Handicraft",
  "Religious Goods & Material",
  "Auto Parts & Repair",
  "CSP & BC Point",
  "Coaching & Tuition Center",
  "Marriage Music & Photography",
  "Tent House & Marriage Goods",
  "Micro Food Production",
  "Flour Mill",
  "Milk Parlor & Agency",
  "Pet Food & Supply",
  "Gymnasium",
  "PC Booth & Photocopy",
  "Paper Mill & Printing House",
  "Diagnostics",
  "Doctor & Medical Clinic",
  "Homeopathy & Ayurvedic Medicine",
  "Opticals & Spectacles",
  "Furniture",
  "Hardware & Building Tools",
  "Home Décor & Interior Material",
  "Plumbing & Building Material",
  "Catering",
  "Tiffin Service",
  "Welding Work & Fabrication",
  "Carpentry",
  "Photo & Video Studio",
  "Lodge & Mini Hotel",
  "Others (Specify)",
];
export const incompletedocumenttype = [
  { id: "Rent Agreement", name: "Rent Agreement" },
  { id: "Utility Bills", name: "Utility Bills" },
  { id: "GST", name: "GST" },
  { id: "ITR", name: "ITR" },
  { id: "Bank Statement", name: "Bank Statement" },
];

export const BusinessPrimeDisposition = [
  "Visit Completed- Interested",
  "Revisit",
  "Not Interested",
];
export const BusinessPrimeDispositionNotInterested = [
  "High Cost of the Product",
  "Satisfied with the Existing service",
  "Additional features - Card payment acceptance, Music, Calculator holder not available",
  "Come back later next month",
  "Not Required at All",
];
export const collectionPQListPQDisposition = [
  "Promise to pay",
  "Customer not available",
  "Already Paid",
  "Dispute Case",
  "Genuine non-payment reason",
  "Refuse to pay",
  "Payment Collected",
  "Unpaid Scenario",
  "Not Contactable",
  "RTP (Refuse To Pay)",
  "Fake-PTP",
  "Broken-PTP",
  "Genuine-PTP",
  "Death",
  "Payment Collected",
  "Already Paid",
  "Shop Closed",
];

export const BusinessPrimeDispositionRevisit = [
  "Shop Closed",
  "Customer Not available",
  "Shop not in location",
  "Insufficient Documents",
  "Customer Denied to give Device"
];
export const BusinessPrimeDispositionRevisitDevliy = [
  "Shop Closed",
  "Customer Not available",
  "Shop not in location",
  "Insufficient Documents",
  // "Customer Denied to give Device"
];
export const BusinessPrimeDispositionRevisitview = [
  "Shop Closed",
  "Customer Not available",
  "Shop not in location",
  "Insufficient Documents",
  "Revisit",
];
export const businessPrimecasteCategory = ["General", "OBC", "ST", "SC"];

export const CollectionandPQDisposition = [
  "RTP (Refuse To Pay)",
  "Fake-PTP",
  "Broken-PTP",
  "Genuine-PTP",
  "Death",
  "Payment Collected",
  "Already Paid",
  "Shop Closed",
];
export const RTPByCustomer = [
  "Intentional",
  "Local Negative Influence",
  "No Business/ Shop Permanently Closed",
];
export const RTPByFamilyMember = [
  "Intentional",
  "Borrower Not Present/Missing",
  " Business Closed/ Shop Permanently Closed",
];
export const FakePTPSubDisposition = [
  "Intentional",
  "Shop Temporarily Closed",
  " Business Closed/ Shop Permanently Closed",
  "Borrower Migrated",
  "Borrower & Family Migrated",
];
export const BrokenPTPSubDisposition = [
  "Business Downfall",
  "Shop Temporarily Closed",
  "Family Event (Marriage/Health)",
  "Over Borrowing /Over indebtness",
];
export const daysAdmitted = ["1-6 days", "7 & above"];
export const GenuinePTPSubDisposition = [
  "Genuine-Health Reason",
  "Genuine-Business/Shop Closure",
  " Geniune-Business Slowdown",
  "Geniune-Salaried",
  "Geniune-Dispute",
];
export const DeathSubDisposition = [
  "Immediate family",
  "Borrower Death",
  "Co-Borrower Death",
];
export const familyMembers = [
  "Father",
  "Mother",
  "Daughter",
  "Son",
  "Husband",
  "Wife",
];
export const deathReason = ["Sudden", "Disease", "Accident"];
export const PaymentDisposition = [
  "Full Payment",
  "Part Payment",
  "Minimum Payment",
];
export const AlreadyPaidDisposition = ["Paid Full", "Loan Closed"];
export const collectionSubDisposition = [
  "Borrower",
  "Co-Borrower",
  "Sister",
  "Brother",
  "Mother",
  "Father",
  "Other Relative",
];
export const collectionSubDisposition3 = ["Demand", "Legal", "NA"];
export const collectionSubDisposition5 = [
  "Badly Behaved",
  "Sad/Stressed",
  "Happy/Normal",
  "Embarrased/Scared",
  "Negatively Influenced",
];
export const verificationStatusFieldVerification = [
  "All Good","Not Eligible","Hold to Review","Other",
];
