import axios, { AxiosResponse } from "axios";
import React from "react";
import { partnerHeader } from "../base";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "../encryption";
import { Navigate } from '../navigate';

export class PartnerService {
  private ARTH_APP_URL: string = process.env.REACT_APP_ARTH_APP_BASE_URL;
  private REACT_APP_USER_CREDENTIALS: string =
  
    process.env.REACT_APP_USER_CREDENTIALS;
  refreshTokenUserCredentials = (): Promise<AxiosResponse<any>> => {
    const refreshToken = Cookies.get("partnerRefreshToken");
    if (!refreshToken) {
      console.error("No refresh token found in cookies.");
      return Promise.reject(new Error("Refresh token missing"));
    }

    const payloads = { refresh: refreshToken };
    const payload = { data: encrypt(payloads) };

    return axios
      .post(`${this.ARTH_APP_URL}/api/token/refresh/`, payload)
      .then((response) => {
        let res = decrypt(response?.data);
        if(res){
          Cookies.set("partnerAccessToken", res?.access, {
            secure: true,
            sameSite: "Strict",
          }); // Update access token
          Cookies.set("partnerRefreshToken", res?.refresh, {
            secure: true,
            sameSite: "Strict",
          });
          return res;
        }
        
      })
      .catch((error) => {
        let err = decrypt(error?.response?.data);
       
        //  let navigate = useNavigate()
        //  console.log("gg", Navigate())
          // Handle specific HTTP error codes
          if (err?.code === "token_not_valid") {
            console.error("Unauthorized: Refresh token is invalid or expired.");
            Cookies.remove("partnerAccessToken"); // Remove expired token
            Cookies.remove("partnerRefreshToken");
            localStorage.clear();
            Navigate()
           
        } else {
          return err;
        }

        return Promise.reject(err);
      });
  };
  authenticateUserCredentials = async (
    payloads: any
  ): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };

    try {
      const response = await axios.post(
        `${this.ARTH_APP_URL}/auth/user/login`,
        payload
      );
      const responses = decrypt(response?.data);
      const datas:any = {"data":responses};
      return datas;
    } catch (err) {
     let error = decrypt(err?.response?.data)
      // if (error && error?.response?.data?.code === "token_not_valid") {
      //   console.warn("Token expired, attempting refresh...");
      //   try {
      //     await this.refreshTokenUserCredentials();
      //     return this.authenticateUserCredentials(payloads); // Retry login after refresh
      //   } catch (refreshError) {
      //     console.error("Failed to refresh token:", refreshError);
      //     throw refreshError;
      //   }
      // } else {
        return error;
      // }
    }
  };

  logoutUser = (): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/auth/user/logout`,
      {},
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      
      let err = decrypt(error?.response?.data);
      
      if (err && err?.code === "token_not_valid") {
          await this.refreshTokenUserCredentials();
          return this.fetchRoleOfUser(); // Retry login after refresh
        
      } else {
        return err;
      }
    });
  };

  fetchRoleOfUser = async (): Promise<AxiosResponse<any>> => {
    return axios
      .get(`${this.ARTH_APP_URL}/auth/get_user_roles/`, {
        headers: partnerHeader(),
      })
      .then(async(res) => {
        const datas:any = {"data":decrypt(res?.data)};
      
        return datas;
      })
      .catch(async (error) => {
        
        let err = decrypt(error?.response?.data);
        
        if (err && err?.code === "token_not_valid") {
            await this.refreshTokenUserCredentials();
            return this.fetchRoleOfUser(); // Retry login after refresh
          
        } else {
          return err;
        }
      });
  };

  fetchUserProfile = async (): Promise<AxiosResponse<any>> => {
    return axios
      .get(`${this.ARTH_APP_URL}/auth/fetch_user_profile/`, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
            await this.refreshTokenUserCredentials();
            return this.fetchUserProfile(); 
        } else {
          return err;
        }
      });
  };

  requestMobileOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/mobile_otp_request/`, payload, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.requestMobileOtp(payloads); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  verifyMobileOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/mobile_otp_verify/`, payload, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.verifyMobileOtp(payloads); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  requestEmailOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/email_otp_request/`, payload, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.requestEmailOtp(payloads); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  verifyEmailOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/email_otp_verify/`, payload, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.verifyEmailOtp(payloads); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  checkDuplicay = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(
        `${this.ARTH_APP_URL}/partners/check-mobile-email-duplicacy/`,
        payload,
        { headers: partnerHeader() }
      )
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.checkDuplicay(payloads);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  requestSignUpEmailOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/signup_email_otp_request/`, payload)
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.requestSignUpEmailOtp(payloads);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  verifySignupEmailOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/signup_email_otp_verify/`, payload)
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.verifySignupEmailOtp(payloads);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  requestSignupMobileOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/signup_mobile_otp_request/`, payload)
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.requestSignupMobileOtp(payloads);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  verifySignupMobileOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/signup_mobile_otp_verify/`, payload)
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.verifySignupMobileOtp(payloads);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  fetchBankName = (ifscCodes: string): Promise<AxiosResponse<any>> => {
    let data = {}
    if(ifscCodes) {
      data['bank_ifs_code'] = ifscCodes
    }
    const queryString = new URLSearchParams(data).toString();
    return axios
      .get(
        `${this.ARTH_APP_URL}/partners/get_bank_name?query_params=${encrypt(queryString)}`,
        { headers: partnerHeader() }
      )
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchBankName(ifscCodes);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  fetchAccountHolderName = (
    ifscCodes: string,
    accountNumbers: string
  ): Promise<AxiosResponse<any>> => {
   
    let data = {}
    if(ifscCodes) {
      data['bank_ifs_code'] = ifscCodes
    }
    if(accountNumbers) {
      data['bank_account_number'] = accountNumbers
    }
    const queryString = new URLSearchParams(data).toString();
    return axios
      .get(
        `${this.ARTH_APP_URL}/partners/get_bank_account_holder_name/?query_params=${encrypt(queryString)}`,
        { headers: partnerHeader() }
      )
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchAccountHolderName(ifscCodes, accountNumbers);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  submitBankDetails = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios
      .post(`${this.ARTH_APP_URL}/partners/bank_details/`, payload, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.submitBankDetails(payloads);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };

  fetchBankDetails = (ids: number): Promise<AxiosResponse<any>> => {
    let data = {}
    if(ids) {
      data['partner_fk'] = ids
    }
    const queryString = new URLSearchParams(data).toString();
    return axios
      .get(`${this.ARTH_APP_URL}/partners/bank_details/?query_params=${encrypt(queryString)}`, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchBankDetails(ids);
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };
  
  fetchRetailsList = (
    search,
    filter,
    pageNo,
    ids,
    funnelType
): Promise<AxiosResponse<any>> => {
    // Encrypting only values
    // const id = encrypt(ids);
    
    // Construct base URL
    let url = `${this.ARTH_APP_URL}/partners/retailer-view/${ids}/?`;

    // Query parameters object
    const queryParams: Record<string, string> = {};

    if (search) {
      queryParams["search"] = search;
    } 
    if (filter === "Paid"){
      queryParams["paid"] ="true" ;
    } 
    if (filter === "Due") {
      queryParams["due"] = "true";
    }
    if (funnelType === "Overall"){
      queryParams["filter_period"] = "all";
    }
    if (funnelType === "Month") {
      queryParams["filter_period"] = "current_month"};
    if (funnelType === "Day") {
      queryParams["filter_period"] = "today";
    };
    if(pageNo){
      queryParams["page"] = pageNo;
      queryParams["per_page"] = "20";
    }
    
    
    const queryString = new URLSearchParams(queryParams).toString();
    let data = encrypt(queryString)
    let dats = decrypt(data);
    
    url += queryString ? `query_params=${encrypt(queryString)}` : "";
   
    return axios.get(url, { headers: partnerHeader() }).
    then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchRetailsList (
            search,
            filter,
            pageNo,
            ids,
            funnelType
        );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
};
  fetchRetailsListSearch = (
    search,
    filter,
    pageNo,
    id,
    funnelType
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/search-retailer/${id}/?`;
    const queryParams: Record<string, string> = {};
    if (search) {
      queryParams["search"] = search;
    } 
    if (filter === "Paid"){
      queryParams["paid"] ="true" ;
    } 
    if (filter === "Due"){
      queryParams["due"] ="true" ;
    } 
    if (funnelType === "Overall"){
      queryParams["filter_period"] ="all" ;
    } 
    if (funnelType === "Month"){
      queryParams["filter_period"] ="current_month" ;
    }
    if (funnelType === "Day") {
      queryParams["filter_period"] = "today";
    };
      queryParams["page"] = pageNo;
      queryParams["per_page"] = "20";
    
    const queryString = new URLSearchParams(queryParams).toString();
    url += queryString ? `query_params=${encrypt(queryString)}` : "";
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchRetailsListSearch (
            search,
            filter,
            pageNo,
            id,
            funnelType
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  submitRetails = (payloads: any, id: number): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/retailer-view/${id}/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.submitRetails (
            payloads,
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  fetchTopfiveRetailsList = (filter, id): Promise<AxiosResponse<any>> => {
    
    let url = `${this.ARTH_APP_URL}/partners/top-5-retailers/${id}/?`;
    const queryParams: Record<string, string> = {};
    if (filter === "Overall") {
      queryParams["filter_period"] = 'all';
    } 
    if (filter === "Month") {
      queryParams["filter_period"] = 'current_month';
    } 
    if (filter === "Day") {
      queryParams["filter_period"] = 'today';
    } 
    // if (filter === "Overall") {
    //   url += encrypt(`filter_period=all&`);
    // }
    // if (filter === "Month") {
    //   url += encrypt(`filter_period=current_month&`);
    // }
    // if (filter === "Day") {
    //   url += `filter_period=today&`;
    //}
    const queryString = new URLSearchParams(queryParams).toString();
    url += queryString ? `query_params=${encrypt(queryString)}` : "";
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchTopfiveRetailsList (
            filter,
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchInvoiceListByRetailers = (
    id,
    retailerbyid,
    search,
    pageNo,
    filter,
    funnelType
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/retailer-invoice-listing/${id}/${retailerbyid}/?`;
    const queryParams: Record<string, string> = {};

    if (search) queryParams["search"] = search;
    if (filter === "Paid") queryParams["paid"] = "true";
    if (filter === "Due") queryParams["due"] = "true";
    if (funnelType === "Overall") queryParams["filter_period"] = "all";
    if (funnelType === "Month") queryParams["filter_period"] = "current_month";
    if (funnelType === "Day") queryParams["filter_period"] = "today";

    // Always add pagination
    queryParams["page"] = pageNo.toString();
    queryParams["per_page"] = "20";

    // Convert query params object to string & append to URL
    // const queryParams: Record<string, string> = {};
    const queryString = new URLSearchParams(queryParams).toString();
    url += queryString ? `query_params=${encrypt(queryString)}` : "";

    // url += `page=${pageNo}&per_page=${20}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchInvoiceListByRetailers(
            id,
            retailerbyid,
            search,
            pageNo,
            filter,
            funnelType
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  fetchGetRetailersById = (
    id: Number,
    retailerbyid: Number,
    filterData: string,
    filter: string
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/get-retailer/${id}/${retailerbyid}`;
    let queryParams: Record<string, string> = {};

    // Encrypt and add filterData conditions
    if (filterData === "Overall") queryParams["filter_period"] = "all";
    if (filterData === "Month") queryParams["filter_period"] = "current_month";
    if (filterData === "Day") queryParams["filter_period"] = "today";

    // Encrypt and add filter conditions
    if (filter === "Paid") queryParams["paid"] = "true";
    if (filter === "Due") queryParams["due"] = "true";
    
    const queryString = new URLSearchParams(filter).toString();
    url += queryString ? `query_params=${encrypt(queryString)}` : "";
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
     
      const datas:any = {"data":decrypt(res?.data)};
      
      return datas;
    })
    .catch(async (error) => {
    
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchGetRetailersById(
            id,
            retailerbyid,
            filterData,
            filter
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  submitSpocDetails = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/partners/spoc_details/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.submitSpocDetails(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchDisbursalDetails = (id: Number): Promise<AxiosResponse<any>> => {
    let queryParams: Record<string, string> = {};
    if (id) {
        queryParams["partner_fk"] = encrypt(id);
    }
    const queryString = new URLSearchParams(queryParams).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/partners/disbursal_limits/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchDisbursalDetails(
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  submitDisbursalDetails = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/disbursal_limits/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.submitDisbursalDetails(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  requestSurepassOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/request-surepass-otp/E-Aadhar/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.requestSurepassOtp(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  verifySurepassOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/verify-surepass-otp/E-Aadhar/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.verifySurepassOtp(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchDigilockerUrl = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/get-redirect-digilocker/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchDigilockerUrl(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  updateDigilockerBrowserEvent = (
    payloads: any
  ): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/partners/browserevent/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateDigilockerBrowserEvent(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchDigilockerStatus = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/verifydigilocker/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchDigilockerStatus(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  validateKycDoc = (
    payloads: any,
    type: string
  ): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-doc-verify/${type}`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.validateKycDoc(
            payloads, type
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  validateUdyamNumber = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-number-verify/U-Aadhar`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.validateUdyamNumber(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  validateGSTINumber = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-number-verify/GSTIN`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.validateGSTINumber(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  validatePanNumber = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-number-verify/PAN`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.validatePanNumber(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchPartnerSpocDetails = (id: number): Promise<AxiosResponse<any>> => {
    let url= {};
    if (id) {
      url['partner_fk']=id;
     };
     const queryString = new URLSearchParams(url).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/partners/spoc_details/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchPartnerSpocDetails(
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchPincodeDetails = (pincode: string): Promise<AxiosResponse<any>> => {
    let url= {};
    if (pincode) {
      url['pincode']=pincode;
     };
     const queryString = new URLSearchParams(url).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/partners/fetch_pincode_details/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchPincodeDetails(
            pincode
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchPartnerList = (
    search: any,
    filter: string,
    pageNo: number
  ): Promise<AxiosResponse<any>> => {
    const payloads = {
      page_num: pageNo,
      records_per_page: 20,
      filters: filter,
      search: search,
    };
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/partners/info/all/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchPartnerList(
            search,
    filter,
    pageNo
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  updatePartnerInfo = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/partners/info/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updatePartnerInfo(
            payloads
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getPartnerInfo = (id: number): Promise<AxiosResponse<any>> => {
    let url= {};
    if (id) {
      url['partner_fk']=id;
     };
     const queryString = new URLSearchParams(url).toString();
    return axios.get(`${this.ARTH_APP_URL}/partners/info/?query_params=${encrypt(queryString)}`, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getPartnerInfo(
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  sendSignUpLinkToPartner = (id: number): Promise<AxiosResponse<any>> => {
    const payloads = {
      partner_id: id,
    };
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/send_invite_link/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.sendSignUpLinkToPartner(
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchEmailIdOnSignup = (id: number): Promise<AxiosResponse<any>> => {
    let url= {};
    if (id) {
      url['partner_id']=id;
     };
     const queryString = new URLSearchParams(url).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/partners/fetch_email_id/?query_params=${encrypt(queryString)}`
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchEmailIdOnSignup(
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  sendSignUpOtpRequest = (email: string): Promise<AxiosResponse<any>> => {
    const payloads=  {
      email: email
    }
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_otp_request/`, payload).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.sendSignUpOtpRequest(
            email
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchDirtyField = (id: number): Promise<AxiosResponse<any>> => {
   let url= {};
      if (id) {
        url['partner_fk']=id;
       };
       const queryString = new URLSearchParams(url).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/partners/qc_remarks/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchDirtyField(
            id
          );
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchHlpLeads = (
    searchTerm,
    isComplete,
    partnerId,
    pageNo,
    startDate,
    endDate,
    filter_status
  ): Promise<AxiosResponse<any>> => {
    if (
      localStorage.getItem("currentRole") === "CentralOps" ||
      localStorage.getItem("currentRole") === "Partnership Manager"
    ) {
      let url = `${this.ARTH_APP_URL}/partners/get-partner-leads/?`;
      const queryParams: Record<string, string> = {};

      if (isComplete) queryParams["is_complete"] = isComplete;
      if (searchTerm) queryParams["search"] = searchTerm;
      if (pageNo) queryParams["page"] = pageNo;
      if (filter_status) queryParams["filter_status"] = filter_status;
      if (startDate) queryParams["start_date"] = startDate;
      if (endDate) queryParams["end_date"] =endDate;
  
      const queryString = new URLSearchParams(queryParams).toString();
      url += queryString ? `query_params=${encrypt(queryString)}` : ""
      return axios.get(
        `${url}`,
        { headers: partnerHeader() }
      ).then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchHlpLeads(
              searchTerm,
              isComplete,
              partnerId,
              pageNo,
              startDate,
              endDate,
              filter_status
            ); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
    } else {
      let url = `${this.ARTH_APP_URL}/partners/get-partner-leads/${partnerId}/?`;
      const queryParams: Record<string, string> = {};

      if (isComplete) queryParams["is_complete"] = isComplete;
      if (searchTerm) queryParams["search"] = searchTerm;
      if (pageNo) queryParams["page"] = pageNo;
      if (filter_status) queryParams["filter_status"] = filter_status;
      if (startDate) queryParams["start_date"] = startDate;
      if (endDate) queryParams["end_date"] =endDate;
  
      const queryString = new URLSearchParams(queryParams).toString();
      url += queryString ? `query_params=${encrypt(queryString)}` : ""
      return axios.get(
        `${url}`,
        { headers: partnerHeader() }
      ).then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchHlpLeads(
              searchTerm,
              isComplete,
              partnerId,
              pageNo,
              startDate,
              endDate,
              filter_status
            ); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
    }
  };

  hlpCafDisposition = (payloads): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/bdo_hlp_tasks/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.hlpCafDisposition(
            payloads
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchHlpLeadsnew = (
    searchTerm,
    isComplete,
    partnerId,
    pageNo,
    startDate,
    endDate,
    filter_status,
    centerList,
    productTypeValue,
    selectedOptions,
    totalperpage,
    searchtype,
    taskName,
    loanAmount,
    dpdBucket,
    disFromDate,
    disToDate,
    partnerlistData
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/partners/get-partner-leads/`;
    let url = {};
    // Check if search is not null or undefined
    if (searchTerm && searchtype === "Search") {
      url["search"] = searchTerm;
    }

    // Check if active is true
    if (dpdBucket) {
      url["dpd_days"] = dpdBucket;
    }
    if (loanAmount) {
      url["loan_amount"] = loanAmount;
    }
    if (partnerlistData) {
      url["partner_name"] = partnerlistData;
    }
    if (disToDate) {
      url["dis_end_date"] = disToDate;
    }
    if (disFromDate) {
      url["dis_start_date"] = disFromDate;
    }
    if (centerList) {
      url["branch_id"] = centerList;
    }
    if (searchTerm && searchtype === "Employee Name") {
      url["employee_name"] = searchTerm;
    }
    // if(isComplete) {
    //   url['is_complete'] =isComplete;
    // }
    // if (selectedOptions) {
    //   url['status']=selectedOptions;
    // }
    if (taskName) {
      url["filter_status"] = taskName;
    }
    if (endDate) {
      url["end_date"] = endDate;
    }
    if (startDate) {
      url["start_date"] = startDate;
    }
    if (pageNo) {
      url["page"] = pageNo;
      url["is_complete"] = isComplete;
      //url['per_page'] = 20
    }
    // const queryParams: Record<string, string> = {};
    const queryString = new URLSearchParams(url).toString();
    const queryStrings= queryString ? `?query_params=${encrypt(queryString)}` : "";
    if (
      localStorage.getItem("currentRole") === "CentralOps" ||
      localStorage.getItem("currentRole") === "Partnership Manager"
    ) {
      return axios.get(`${url1}${queryStrings}`,{ headers: partnerHeader() }).then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchHlpLeadsnew(
              searchTerm,
              isComplete,
              partnerId,
              pageNo,
              startDate,
              endDate,
              filter_status,
              centerList,
              productTypeValue,
              selectedOptions,
              totalperpage,
              searchtype,
              taskName,
              loanAmount,
              dpdBucket,
              disFromDate,
              disToDate,
              partnerlistData
            ); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
    } else {
      return axios.get(`${url1}${partnerId}${queryStrings}`, {
        // params: `query_params=${encrypt(url)}`,
        headers: partnerHeader(),
      }).then((res) => {
        const datas:any = {"data":decrypt(res?.data)};
        return datas;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.fetchHlpLeadsnew(
              searchTerm,
              isComplete,
              partnerId,
              pageNo,
              startDate,
              endDate,
              filter_status,
              centerList,
              productTypeValue,
              selectedOptions,
              totalperpage,
              searchtype,
              taskName,
              loanAmount,
              dpdBucket,
              disFromDate,
              disToDate,
              partnerlistData
            ); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
    };
    
  };

  updatePartnerProfileRequests = (payloads: {}): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/profile_update_requests/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updatePartnerProfileRequests (
            payloads
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchCustomerData = (
    mobile: string,
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    let data= {
      "mobile": mobile
    }
    // const queryParams: Record<string, string> = {};
    const queryString = new URLSearchParams(data).toString();
   
    return axios.get(
      `${this.ARTH_APP_URL}/partners/customer-invoice/${partnerId}?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchCustomerData (
            mobile,
            partnerId
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  createPartnerInvoicebyRetailersid = (
    payloads: {},
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/retailer-invoice-view/${partnerId}/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.createPartnerInvoicebyRetailersid (
            payloads,
            partnerId
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updatedPartnerInvoicebyRetailersid = (
    payloads: {},
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.patch(
      `${this.ARTH_APP_URL}/partners/retailer-invoice-view/${partnerId}/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updatedPartnerInvoicebyRetailersid (
            payloads,
            partnerId
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  createPartnerInvoice = (
    payloads: {},
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(
      `${this.ARTH_APP_URL}/partners/customer-invoice/${partnerId}/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.createPartnerInvoice (
            payloads,
            partnerId
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchInvoiceData = (
    invoiceId: string,
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    let invoiceids = {
      "search":invoiceId
    }
    // const queryParams: Record<string, string> = {};
    const queryString = new URLSearchParams(invoiceids).toString();
   
    return axios.get(
      `${this.ARTH_APP_URL}/partners/get-invoice-details/${partnerId}/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchInvoiceData (
            invoiceId,
            partnerId
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  fetchInvoices = (
    partnerId,
    search,
    pageNo
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/get-invoice-details/${partnerId}/?`
    const queryParams: Record<string, string> = {};
    if (search) {queryParams["search"] = search};
    if (pageNo) {queryParams["page"] = pageNo.toString()};
    queryParams["per_page"] = "20";
    const queryString = new URLSearchParams(queryParams).toString();
    url += queryString ? `query_params=${encrypt(queryString)}` : "";
    return axios.get(
      `${url}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.fetchInvoices (
            partnerId,
            search,
            pageNo
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  generateInvoicePdf = (invoiceId: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/generate-invoice-pdf/${invoiceId}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.generateInvoicePdf(invoiceId); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getPartnerNameList = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/partners-listing/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getPartnerNameList(); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getLeadList = (payloads): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/hotleadapi/hotleadlist/?`
    // const queryParams: Record<string, string> = {};
    const queryString = new URLSearchParams(payloads).toString();
    url += queryString ? `query_params=${encrypt(queryString)}` : "";
    return axios.get(`${url}`, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getLeadList(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  createCustomerLead = (payloads: {}): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/hotleadlist/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.createCustomerLead(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getOtp = (payloads): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/hotleadlist/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getOtp(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getEmailOTP = (payloads): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/getemailotp/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmailOTP(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  verifyEmailOTP = (page): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/getemailotp/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.verifyEmailOTP(page); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updateCustomerLead = (payloads, id): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.patch(`${this.ARTH_APP_URL}/getdetail/${id}/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateCustomerLead(payloads,id); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getCustomerLeadDetails = (id): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/getdetail/${id}/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getCustomerLeadDetails(id); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getPinCodeDetail = (payloads, id): Promise<AxiosResponse<any>> => {
    const payload = {"data": encrypt(payloads)}
    return axios.patch(`${this.ARTH_APP_URL}/getdetail/${id}/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getPinCodeDetail(payloads,id); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  rejectLead = (payloads): Promise<AxiosResponse<any>> => {
     const payload = {"data": encrypt(payloads)}
    return axios.post(`${this.ARTH_APP_URL}/hotleadlist/status`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.rejectLead(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  formOptions = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/api/getcofform`, {
      headers: partnerHeader(),
    }).then((res) => {
      const datas:any = {"data":decrypt(res?.data)};
      return datas;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.formOptions(); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
}
