import axios, { AxiosResponse } from "axios";
import { headers, partnerHeader, partnerHeaderFromdata } from "../base";
import { id } from "date-fns/locale";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "../encryption";
import { useNavigate } from 'react-router-dom';
import { Navigate } from '../navigate';

export class SalesService {
  private ARTH_APP_URL: string = process.env.REACT_APP_ARTH_APP_BASE_URL;
  private ARTH_URL: string = process.env.REACT_APP_ARTH_APP_URL;
 
  refreshTokenUserCredentials = (): Promise<AxiosResponse<any>> => {
    const refreshToken = Cookies.get("partnerRefreshToken");
    if (!refreshToken) {
      return Promise.reject(new Error("Refresh token missing"));
    }

    const payloads = { refresh: refreshToken };
    const payload = { data: encrypt(payloads) };

    return axios
      .post(`${this.ARTH_APP_URL}/api/token/refresh/`, payload)
      .then((response) => {
        let res = decrypt(response?.data);
        if(res){
          Cookies.set("partnerAccessToken", res?.access, {
            secure: true,
            sameSite: "Strict",
          }); // Update access token
          Cookies.set("partnerRefreshToken", res?.refresh, {
            secure: true,
            sameSite: "Strict",
          });
          return res;
        }
        
      })
      .catch((error) => {
        let err = decrypt(error?.response?.data);
       
          if (err?.code === "token_not_valid") {
            Cookies.remove("partnerAccessToken"); // Remove expired token
            Cookies.remove("partnerRefreshToken");
            localStorage.clear();
            Navigate()
           
        } else {
          return err;
        }

        return Promise.reject(err);
      });
  };
  authenticateUserCredentials = async (
    payloads: any
  ): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };

    try {
      const response = await axios.post(
        `${this.ARTH_APP_URL}/auth/user/login`,
        payload
      );
      const responses = decrypt(response?.data);
      const datas:any = {"data":responses};
      return datas;
    } catch (err) {
     let error = decrypt(err?.response?.data)
      // if (error && error?.response?.data?.code === "token_not_valid") {
      //   console.warn("Token expired, attempting refresh...");
      //   try {
      //     await this.refreshTokenUserCredentials();
      //     return this.authenticateUserCredentials(payloads); // Retry login after refresh
      //   } catch (refreshError) {
      //     console.error("Failed to refresh token:", refreshError);
      //     throw refreshError;
      //   }
      // } else {
        return error;
      // }
    }
  };

  logoutUser = (): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/auth/user/logout`,
      {},
      { headers: partnerHeader() }
    );
  };

  fetchRoleOfUser = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/auth/get_user_roles/`, {
      headers: partnerHeader(),
    });
  };

  getBranchList = (): Promise<AxiosResponse<any>> => {
    return axios
      .get(`${this.ARTH_APP_URL}/sales_app/v1/branch_list/`, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const responses:any = {"data":decrypt(res?.data)};
        return responses;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.getBranchList(); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
   
  };
  getListRPM = (): Promise<AxiosResponse<any>> => {
    return axios
      .get(`${this.ARTH_APP_URL}/sales_app/v1/role_list/`, {
        headers: partnerHeader(),
      })
      .then((res) => {
        const responses:any = {"data":decrypt(res?.data)};
        return responses;
      })
      .catch(async (error) => {
        let err = decrypt(error?.response?.data);
        if (err && err?.code === "token_not_valid") {
          try {
            await this.refreshTokenUserCredentials();
            return this.getListRPM(); // Retry login after refresh
          } catch (refreshError) {
            let refreshErrors = decrypt(refreshError);
            console.error("Failed to refresh token:", refreshError);
            throw refreshErrors;
          }
        } else {
          return err;
        }
      });
  };
  getHuntingPartnersList = (): Promise<AxiosResponse<any>> => {
    return axios
    .get(`${this.ARTH_APP_URL}/sales_app/v1/hunting_partners_list/`, {
      headers: partnerHeader(),
    })
    .then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getListRPM(); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
   
  };
  createHunter = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/create_hunter/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.createHunter(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updateHunter = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/update_hunter/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateHunter(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getbyhunterListDetails = (id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunter_list/${encrypt(id)}`;
    // url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getbyhunterListDetails(id); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getBranchListPQVisit = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/user/branch_list/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getBranchListPQVisit(); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getHunterList = (search, filter, pageNo): Promise<AxiosResponse<any>> => {
    // console.log("searchfilterPageno:",search, filter, pageNo);
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunter_list/?`;
    let url1 = {};
    // Check if search is not null or undefined
    if (search) {
      url1 += `search=${search}&`;
    }

    // Check if active is true
    if (filter) {
      url1 += `active=${filter}&`;
    }
    if(pageNo){
      url1 += `page=${pageNo}&`;
      url1 += `per_page=${20}`; 
    }
    const queryString = new URLSearchParams(url1).toString();
    // Add the page number parameter
    url += queryString ? `query_params=${encrypt(queryString)}`:"";

    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getHunterList(search, filter, pageNo); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  //sales_app/v1/update_hunter/'

  requestMobileOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/request_otp/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.requestMobileOtp(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  verifyMobileOtp = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/verify_otp/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.verifyMobileOtp(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getStateandcity = (pincode: string): Promise<AxiosResponse<any>> => {
    let data= {}
    if(pincode) {
      data['pincode']=pincode
    }
    const queryString = new URLSearchParams(data).toString();
    // Add the page number parameter
   
    return axios.get(
      `${this.ARTH_APP_URL}/hotleadapi/pincode/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getStateandcity(pincode); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getProductQuestionList = (): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/product_question_list/`,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getProductQuestionList(); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getPanVerify = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/pan_verify/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getPanVerify(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  createHuntingForm = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/create_hunting_form/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.createHuntingForm(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  autheticateHuntingForm = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/autheticate_lead/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.autheticateHuntingForm(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updateHuntingForm = (payloads: any): Promise<AxiosResponse<any>> => {
    // const payload = { data: encrypt(payloads) };
    // console.log("l",partnerHeaderFromdata(), payload)
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/update_in_progress_form/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateHuntingForm(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getDistance = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/get_distance/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getDistance(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updateQCAction = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(`${this.ARTH_APP_URL}/sales_app/v1/qc_action/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateQCAction(payloads); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  //sales_app/v1/qc_action/'
  getHunterLeadList = (
    pageNo: number,
    search: string | null | undefined,
    fromDate: string | null | undefined,
    toDate: string | null | undefined,
    centerList: string | null | undefined,
    productType: number[] | null | undefined,
    selectedOptions: string | null | undefined,
    selectedOptionsUploadType: string | null | undefined
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url = {};
    // Check if search is not null or undefined
    if (search) {
      url["search"] = search;
    }

    // Check if active is true
    if (centerList) {
      url["center"] = centerList;
    }
    if (productType) {
      url["products"] = productType;
    }
    if (selectedOptions) {
      url["status"] = selectedOptions;
    }
    if (selectedOptionsUploadType) {
      url["lead_type"] = selectedOptionsUploadType;
    }
    if (toDate) {
      url["to_date"] = toDate;
    }
    if (fromDate) {
      url["from_date"] = fromDate;
    }
    if (pageNo) {
      url["page"] = pageNo;
      url["per_page"] = 20;
    }
    const queryString = new URLSearchParams(url).toString();
     url1 +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url1}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getHunterLeadList (
            pageNo,
            search,
            fromDate,
            toDate,
            centerList,
            productType,
            selectedOptions,
            selectedOptionsUploadType
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getHunterLeadListFos = (pageNo): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`
    let url1= {};
    if (pageNo) {
      url1["page"] = pageNo;
    }
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getHunterLeadListFos (
            pageNo,
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getHunterLeadListFosQC = (form_id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url1= {};
    if (form_id) {
      url1["form_id"] = form_id;
    }
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getHunterLeadListFosQC (
            form_id,
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getcenterbyemployeename = (branch_id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_branch_tasks/`;
    let url1= {};
    if (branch_id) {
      url1["branch"] = branch_id;
    }
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getcenterbyemployeename (
            branch_id,
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getSimpleDownloadLead = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/download_sample/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getSimpleDownloadLead (
            payloads,
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  uploadExcelLead = (payloads: any): Promise<AxiosResponse<any>> => {
    // const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/upload_excel/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.uploadExcelLead (
            payloads,
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getdigitalhuntinglogs = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/digital_hunting_logs/`;
    let url1= {};
    if (pageNo) {
      url1["page"] = pageNo;
    }
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getdigitalhuntinglogs (
            pageNo,
          ); // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getemployeeList = (
    search,
    filterdata,
    centerName,
    pageNo
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/employee_list/?`;
    // Check if search is not null or undefined
    let url1 = {};
    if (search) {
      url1['search'] = search;
    }

    // Check if active is true
    if (filterdata) {
      url1['role'] = filterdata;
    }
    if (centerName) {
      url1['branch'] = centerName;
    }
    if(pageNo){
     url1['page'] =pageNo;
     url1['per_page']= 20;
    }
  
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getemployeeList (
            search,
            filterdata,
            centerName,
            pageNo
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getmastertaskList = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/master_task_list/`;
    // url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getmastertaskList (
            pageNo
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getHotLeadsTask = (isComplete, page): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/bdo_hlp_tasks/`;
    

    let data = {};

    if (isComplete == false) {
      data["is_complete"] = 'false';
    }
    // if (isComplete === true || isComplete === "" || isComplete === null) {
    //   data["is_complete"] =  null;
    // }
    if (page) {
      data["page"] = page;
    }
    const queryString = new URLSearchParams(data).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :"";
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getHotLeadsTask (
            isComplete, page
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getReport = async () => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/assignment_report/`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getReport() // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
    // try {
    //   let url = `${this.ARTH_APP_URL}/sales_app/v1/assignment_report/`;
    //   const requestOptions = {
    //     headers: partnerHeader(),
    //   };
    //   const response = await fetch(url, requestOptions);
    //   return await response.json();
    // } catch (error) {
    //   console.log("Error - ", error);
    // }
  };

  updateMasterTasks = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/update_master_tasks/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateMasterTasks (
            payloads
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  updateActiveEmployee = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/employee_avialable/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateActiveEmployee (
            payloads
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  updateActiveEmployeeval = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/leave_dates/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateActiveEmployeeval (
            payloads
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getRoleList = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/role_list/`;
    let data= {
      "role":"all"
    };
    const queryString = new URLSearchParams(data).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getRoleList() // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getFetchTaskscentralops = (
    page,
    search,
    fromDate,
    toDate,
    centerList,
    productTypeValue,
    selectedOptions,
    role,
    perpage,
    searchtype,
    taskName
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks/`;
    //let url1 = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url = {};

    // Check if search is not null or undefined
    if (searchtype === "Search" && search) {
      url["search"] = search;
    }
    if (searchtype === "Task Id" && search) {
      url["task_id_filter"] = search;
    }
    if (searchtype === "Employee Id" && search) {
      url["emp_id"] = search;
    }
    if (searchtype === "Employee Name" && search) {
      url["emp_name"] = search;
    }
    // Check if active is true
    if (centerList) {
      url["center"] = centerList;
    }
    if (role) {
      url["role"] = role;
    }
    if (taskName) {
      url["task_type"] = taskName;
    }
    if (selectedOptions) {
      url["status"] = selectedOptions;
    }
    //  if (selectedOptionsUploadType) {
    //    url['lead_type']=selectedOptionsUploadType;
    //  }
    if (toDate) {
      url["end_date"] = toDate;
    }
    if (fromDate) {
      url["start_date"] = fromDate;
    }
    if (page) {
      url["page"] = page;
      url["per_page"] = perpage;
    }
    const queryString = new URLSearchParams(url).toString();
    url1 +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url1}`, {  headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getFetchTaskscentralops(
            page,
            search,
            fromDate,
            toDate,
            centerList,
            productTypeValue,
            selectedOptions,
            role,
            perpage,
            searchtype,
            taskName
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getFetchTasksAlldata = (
    page,
    search,
    fromDate,
    toDate,
    centerList,
    productTypeValue,
    selectedOptions,
    role,
    perpage,
    searchtype,
    taskName
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks/?`;
    //let url1 = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url = {};

    // Check if search is not null or undefined
    if (searchtype === "Search" && search) {
      url["search"] = search;
    }
    if (searchtype === "Task Id" && search) {
      url["task_id_filter"] = search;
    }
    if (searchtype === "Employee Id" && search) {
      url["emp_id"] = search;
    }
    if (searchtype === "Employee Name" && search) {
      url["emp_name"] = search;
    }
    // Check if active is true
    if (centerList) {
      url["center"] = centerList;
    }
    if (role) {
      url["role"] = role;
    }
    if (taskName) {
      url["task_type"] = taskName;
    }
    if (selectedOptions) {
      url["status"] = selectedOptions;
    }
    //  if (selectedOptionsUploadType) {
    //    url['lead_type']=selectedOptionsUploadType;
    //  }
    if (toDate) {
      url["end_date"] = toDate;
    }
    if (fromDate) {
      url["start_date"] = fromDate;
    }
    if (page) {
      url["page"] = page;
      url["per_page"] = perpage;
    }
    const queryString = new URLSearchParams(url).toString();
    url1 +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url1}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getFetchTasksAlldata(
            page,
            search,
            fromDate,
            toDate,
            centerList,
            productTypeValue,
            selectedOptions,
            role,
            perpage,
            searchtype,
            taskName
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getFetchTasksSelf = (
    page,
    search,
    fromDate,
    toDate,
    centerList,
    productTypeValue,
    selectedOptions
  ): Promise<AxiosResponse<any>> => {
   
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks?my_tasks=true`;
    let url1 = {};
    url1['my_tasks'] ="true"
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getFetchTasksSelf(
            page,
            search,
            fromDate,
            toDate,
            centerList,
            productTypeValue,
            selectedOptions
          ) // Retry login after refresh
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  // getFetchTaskCurrentSelf = (): Promise<AxiosResponse<any>> => {
  //   let url = `${this.ARTH_APP_URL}/sales_app/v1/current_task`;
  //   return axios.get(`${url}`, { headers: partnerHeader() });
  // }
  getFetchTaskCurrentSelf = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/current_task/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getFetchTaskCurrentSelf() 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  uploadExcelTaskAssignment = (payloads: any): Promise<AxiosResponse<any>> => {
    // const payload = { data: encrypt(payloads) };
    return axios.put(
      `${this.ARTH_APP_URL}/hotleadapi/taskmanagement/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.uploadExcelTaskAssignment(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  uploadncUpload = (payloads: any): Promise<AxiosResponse<any>> => {
    // const payload = { data: encrypt(payloads) };
    return axios.put(`${this.ARTH_APP_URL}/hotleadapi/nc_upload/`, payloads, {
      headers: partnerHeaderFromdata(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.uploadncUpload(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getExcelTaskAssignment = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/digital_hunting_logs/`;
    let url1 = {};
    url1['page'] =pageNo;
    // let url1 = {"page":pageNo};
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getExcelTaskAssignment(pageNo) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  //v1/shop_information/
  getshopInformation = (taskid: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/shop_information/`;
    // let url1 = {"task_id":taskid};
    let url1 = {};
    url1['task_id'] =taskid;
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
   
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getshopInformation(taskid) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  //v1/add_task_log/
  getaddtaskLog = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/add_task_log/`;
    // let url1 = {"page":pageNo};
    let url1 = {};
    url1['page'] =pageNo;
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    // url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getaddtaskLog(pageNo) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getTaskbyiddata = (taskid: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks/`;
    let url1 = {};
    url1['task_id'] =taskid;
    const queryString = new URLSearchParams(url1).toString();
    url +=  queryString ? `?query_params=${encrypt(queryString)}` :""
    // url += `task_id=${taskid}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getTaskbyiddata(taskid) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getTaskbyiddataval = (taskid: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_task_data/${taskid}/`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getTaskbyiddataval(taskid) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updateFetchTaskCurrentSelf = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/current_task/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updateFetchTaskCurrentSelf(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  updatecafpermission = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/common/v1/set_primary_role/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.updatecafpermission(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getSimpleDownloadTask = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/download-sample/task_format/`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getSimpleDownloadTask() 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getSimpleDownloadTaskMagicQR = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/download-sample/magic_qr_format/`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getSimpleDownloadTaskMagicQR() 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  changeTaskAssignee = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/change_task_assignee/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.changeTaskAssignee(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  RevealNumber = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/reveal_number/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.RevealNumber(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  Createdisposition = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/disposition/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.Createdisposition(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getEmployeebyid = (id): Promise<AxiosResponse<any>> => {
    let url1 = {};
    url1['employee_id'] =id;
    const queryString = new URLSearchParams(url1).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/calendar_data/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeebyid(id) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getnccDownload = (): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/download-sample/nc_format/`,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getnccDownload() 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  UploaddispositionFile = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/upload_dispostion_files/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.UploaddispositionFile(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  AttendanceClockin = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(`${this.ARTH_APP_URL}/sales_app/v1/clockin/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.AttendanceClockin(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getAttendanceClockin = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/clockin/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getAttendanceClockin() 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    })
  };
  getAttendanceClockinbyid = (id): Promise<AxiosResponse<any>> => {
    let url1 = {};
    url1['employee_id'] =id;
    const queryString = new URLSearchParams(url1).toString();
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/clockin/?query_params=${encrypt(queryString)}`,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getAttendanceClockinbyid(id) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  AttendanceClockout = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.patch(`${this.ARTH_APP_URL}/sales_app/v1/clockout/`, payload, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.AttendanceClockout(payloads) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  // getdashboardclc = (): Promise<AxiosResponse<any>> => {
  //   let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/`;
  //   return axios.get(`${url}`, { headers: partnerHeader() });
  // }
  getdashboardclc = (fromdate, todate): Promise<AxiosResponse<any>> => {
    // console.log("searchfilterPageno:",search, filter, pageNo);
    let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/`;
    let url1 = {};
    // Check if search is not null or undefined
    if (fromdate) {
      url1['from_date'] = fromdate;
    }

    // Check if active is true
    if (todate) {
      url1['to_date']= todate;
    }
    const queryString = new URLSearchParams(url1).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getdashboardclc(fromdate, todate) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getdashboardemployeebased = (
    id,
    fromdate,
    todate
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/`;
    let url1= {};
    if (id) {
      url1['employee_id'] = id;
    }
    // Check if search is not null or undefined
    if (fromdate) {
      url1['from_date'] = fromdate;
    }

    // Check if active is true
    if (todate) {
      url1['to_date'] = todate;
    }
    const queryString = new URLSearchParams(url1).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getdashboardemployeebased( id,
            fromdate,
            todate) 
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getEmployeebyList = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/fetch_employees/`, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeebyList();
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getEmployeerolebyList = (branch): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_employees/`;
    let url1= {};
    if (branch) {
      url1['branch_id'] = branch;
    }
    let queryString = new URLSearchParams(url1).toString();
    let datas =encrypt(queryString);
    let datadesypty = decrypt(datas);
    url += queryString ? `?query_params=${encrypt(queryString)}`:'';
    
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeerolebyList(branch);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getEmployeeLists = (branch): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/branch_employee_list/?`;
    let url1= {};
    if (branch) {
      url1["branch_id"]= branch;
    }
    const queryString = new URLSearchParams(url1).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeeLists(branch);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getDispostionDashboardData = (branch, employee) => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/disposition_dashboard/`;
    let data = {};

    if (branch) {
      data["branch_id"] = branch;
    }

    if (employee) {
      data["employee"] = employee;
    }
    const queryString = new URLSearchParams(data).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    return axios.get(`${url}`, {headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getDispostionDashboardData(branch,employee);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getEmployeerolebyListtracking = (
    branch,
    role,
    searchs
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_employees/`;
    let data= {};
    if (branch) {
      data['branch_id'] = branch;
    }
    // Check if search is not null or undefined
    if (role) {
      data['role'] = role;
    }
    if (searchs) {
      data['search'] = searchs;
    }
    const queryString = new URLSearchParams(data).toString();
    url += queryString ? `?query_params=${encrypt(queryString)}`:'';
    
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeerolebyListtracking(branch,
            role,
            searchs);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getLiveTrackingData = (employee_id, date): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/live_track_panel/`;
    let data= {};
    if (employee_id) {
      data['employee_id'] = employee_id;
    }

    if (date) {
      data['date'] = date;
    }
    const queryString = new URLSearchParams(data).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getLiveTrackingData(employee_id, date);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  getEmployeebyidtracking = (
    id,
    keyvalue,
    date
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/live_location/`;
    let data= {};
    if (id) {
      data['employee_id']= id;
    }
    // Check if search is not null or undefined
    if (keyvalue) {
      data['key'] = keyvalue;
    }
    if (date) {
      data['date'] = date;
    }
    const queryString = new URLSearchParams(data).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeebyidtracking(id,
            keyvalue,
            date);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  Employeebytrackingdata = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/live_location/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.Employeebytrackingdata(payloads);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getEmployeebyidtask = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/today_locations/`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeebyidtask();
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  uploadExcelTaskAssignmentMagicQR = (
    payloads: any
  ): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };

    return axios.put(
      `${this.ARTH_APP_URL}/sales_app/v1/magic_upload/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.uploadExcelTaskAssignmentMagicQR(payloads);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  getheatMapLocation = (branch, roledata): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/heat_map/`;
    let data= {};
    if (branch) {
      data['branch_id'] = branch;
    }
    // Check if search is not null or undefined
    if (roledata) {
      data['role'] = roledata;
    }
    const queryString = new URLSearchParams(data).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getheatMapLocation(branch, roledata);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
    //   let url = `${this.ARTH_APP_URL}/sales_app/v1/heat_map/`;
    //  return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getEmployeeChannelList = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/partners-listing/Channel/`;
    return axios.get(`${url}`, { headers: partnerHeader() }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getEmployeeChannelList();
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  validateUdyam = (
    udyam_number: string,
    customer_code: string,
    partner_code: string
  ): Promise<AxiosResponse<any>> => {
    let data = {
      udyam_number:udyam_number,
      customer_code:customer_code,
      partner_code: partner_code
    }
    const payload = { data: encrypt(data) };
    return axios.post(
      `${this.ARTH_APP_URL}/check_valid_udyam`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.validateUdyam( udyam_number,
            customer_code,
            partner_code);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  sendImage = (sdk_user: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.ARTH_APP_URL}/profiles/me`,
      { sdk_user },
      { headers: partnerHeader() }
    );
  };
  UploadAadhar = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/upload_aadhar/`,
      payload,
      { headers: partnerHeader() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.UploadAadhar(payloads);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  CreateFormBsuinessPrime = (payloads: any): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/create_paymint_task/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.CreateFormBsuinessPrime(payloads);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  CreateFormValidateUdyamAadhaarNumber = (
    payloads: any
  ): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/validate_udyam_aadhaar/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.CreateFormValidateUdyamAadhaarNumber(payloads);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
  UpdateFormValidateUdyamAadhaarNumber = (
    payloads: any,
    id
  ): Promise<AxiosResponse<any>> => {
    const payload = { data: encrypt(payloads) };
    return axios.put(
      `${this.ARTH_APP_URL}/sales_app/v1/update_paymint_task/${id}/`,
      payloads,
      { headers: partnerHeaderFromdata() }
    ).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.UpdateFormValidateUdyamAadhaarNumber(payloads, id);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };

  sendEnachLink = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_URL}/v2/enach/sendenachlink/`, payload, {
      headers: headers(),
    });
  };

  getBusinessPrimeTask = (is_complete, page): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/bp_tasks`;
    let data= {};
    if (is_complete) {
      data['complete'] = is_complete;
    }
    if (page) {
      data['page'] = page;
    }
    const queryString = new URLSearchParams(data).toString();
    url += queryString? `?query_params=${encrypt(queryString)}`:'';
    return axios.get(`${url}`, {
      headers: partnerHeader(),
    }).then((res) => {
      const responses:any = {"data":decrypt(res?.data)};
      return responses;
    })
    .catch(async (error) => {
      let err = decrypt(error?.response?.data);
      if (err && err?.code === "token_not_valid") {
        try {
          await this.refreshTokenUserCredentials();
          return this.getBusinessPrimeTask(is_complete, page);
        } catch (refreshError) {
          let refreshErrors = decrypt(refreshError);
          console.error("Failed to refresh token:", refreshError);
          throw refreshErrors;
        }
      } else {
        return err;
      }
    });
  };
}
