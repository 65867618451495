import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Pagination } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./leadList.scss";
import { Link, useNavigate } from "react-router-dom";
export default function LeadListRetailers({
  rows,
  columns,
  totalRecords,
  handlePaginationChange,
  hasPermission,
  type = "others",
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleOnboardingDate = (stamp) => {
    const creationTimestamp = stamp;
    const date = new Date(creationTimestamp);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const getButtonStyle = (productType) => {
    let style = {};
    switch (productType) {
      case "Business Loan":
        style = { background: "#FDE7EF", color: "#EE3E80" };
        break;
      case "Non-Credit":
        style = { backgroundColor: "#E3D9F0", color: "#440099" };
        break;
      case "Paymint":
        style = { backgroundColor: "#D2F5F4", color: "#10BDB8" };
        break;
      default:
        break;
    }
    return style;
  };
  const handleEdit = (id) => {
    navigate(`/retailer/invoice/list/${id}`);
  };
  const handleClick = (id) => {
    
    navigate(`/retailer/invoice/list/${id}`);
  };
  return (
    <>
    <div style={{fontSize:"16px", fontWeight:"bold"}}>
       Total Retailer Count - {totalRecords} 
    </div>
    <div>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          style={{ width: "auto !important" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <div className="tableRow"> {column.label}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns?.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "create_ts" ? (
                          <>
                            <div>
                              <span>{handleOnboardingDate(value)}</span>
                            </div>
                          </>
                        ) : column.id === "name" ? (
                          <>
                            <div
                              className="link-pointer"
                              onClick={() => handleClick(row.id)}
                            >
                              {value}
                            </div>
                          </>
                        ) : (
                          <>
                            {column.format && typeof value === "number" ? (
                              <div className="tablebodytitle">
                                {column.format(value)}
                              </div>
                            ) : (
                              <div
                                className="tablebodytitle"
                                onClick={() =>
                                  handleClick(row.id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {value}
                              </div>
                            )}
                          </>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="mt-4 d-flex justify-content-center">
        <Pagination
          count={Math.ceil(totalRecords / 20)}
          variant="outlined"
          shape="rounded"
          onChange={handlePaginationChange}
        />
      </div>
    </div>
    </>
    
  );
}
