import "./details-box.scss";

function BusinessDetails({ businessDetails, userData,  heading, bigBox = false }) {
  
  const nf = new Intl.NumberFormat();
  
  const loremOne = {
    "Business Name": businessDetails?.business_name,
    "Business Vintage": businessDetails?.business_vintage ?? '',
    "Business Type": businessDetails?.business_type,
    "Occupation": businessDetails?.occupation,
    "Business Since": businessDetails?.business_since,
    "No. of Staff/Worker": businessDetails?.no_of_staff,
    "Business Place": businessDetails?.business_place,
    "Business Category": businessDetails?.business_category,
    "Business Enviornment": businessDetails?.business_environment,
    "Average Salary Per Worker": businessDetails?.worker_avg_salary,
    "Income Type": businessDetails?.income_type
   
  };

  const loremTwo = {
    "Business Address": businessDetails?.business_address??"",
    "Business Pincode": businessDetails?.business_pincode ?? "",
  };

  const loremThree = {
    "Business Monthly Income": nf.format(businessDetails?.business_monthly_income),
  };

  return (
    <div className={`demographics-container ${bigBox && "big-box"}`}>
      <div class="row">
        <div class="col-md basic-details-hdr">{heading}</div>
      </div>
      <div>
        {Object?.keys(loremOne)?.map((key) => (
          <>
          {loremOne[key] !== null && (<>
          
            <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{loremOne[key]}</div>
          </div>
          </>)}
          </>
        
        ))}
         {["Loan against property", "Home Loan", "Balance Transfer","Loan Against Property (LAP)","Home Loan (HL)","Balance Transfer (BT)"].includes(userData?.basic_details?.product) && (<>
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{ "Loan Amount Required" }</div>
            <div class={`col-md basic-right-body`}>{businessDetails?.required_loan_amount}</div>
          </div>
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{ "Property Address" }</div>
            <div class={`col-md basic-right-body`}>{businessDetails?.property_address}</div>
          </div>
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{ "Property Pincode" }</div>
            <div class={`col-md basic-right-body`}>{businessDetails?.property_pincode}</div>
          </div>
         </>)}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(loremTwo).map((key) => (
          <>
          {loremTwo[key] !== null && (<>  <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{loremTwo[key]}</div>
          </div> </>)}
          </>
         
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(loremThree).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{loremThree[key]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BusinessDetails;
