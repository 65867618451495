import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../components/partner-application/breadcrumb/BreadCrumb";
import { AddWhiteSVG, SearchWhiteSVG } from "../../../assets/svg";
import ActionButton from "../../../components/partner-application/button/ActionButton";
import PrimeryButton from "../../../components/partner-application/button/PrimeryButton";
import "../invoice/invoice-index.scss";
import { useNavigate, useParams } from "react-router-dom";
import Filters from "../../../components/sales-application/Filters/Filters";
import { Edit, Save, ArrowBack } from "@mui/icons-material";

import { PartnerService } from "../../../services/api/partnerService";
import { Toaster } from "../../../utils/utilities/Toaster";
import Pagination from "@mui/material/Pagination";
import { Mixpanel } from "../../../routing/MixPannelWrapper";
import Funnel from "../../../components/partner-application/RetailerManagement/Funnel";
import Dropdown from "../../../components/partner-application/dropdown/Dropdown";
import Chart from "../../../components/partner-application/RetailerManagement/Chart";
const breadCrumbData = [{ name: "Retailer Invoice", path: "" }];
const filterValues = ["All", "Due", "Paid"];
const searchList = ["Mobile No", "Name", "ID"];

const RetailerInvoice = () => {
  const navigate = useNavigate();
  const toaster = new Toaster();
  const { id } = useParams(); 
  const [invoices, setInvoices] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [funnelType, setFunnelType] = useState("");
  const [isMobile,setIsMobile] = useState(false);
  const [retailersDetails,setRetailersDetails] = useState(null);
  const partnerService = new PartnerService();
  const partnerId = localStorage.getItem("currentPartnerId");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    fetchInvoices(partnerId, search,filter, pageNo, funnelType);
    fetchRetailersData( funnelType, filter)
  }, []);

  const handleSearchInput = (value) => {
    Mixpanel.identify(localStorage.getItem("currentUserEmail"));
    Mixpanel.track("My Invoice Section - CTA Search", "");
    Mixpanel.people.set({
      $first_name:
        localStorage.getItem("currentUser") +
        "_" +
        localStorage.getItem("currentRole"),
    });
    setSearchText(value.toLowerCase());
  };

  const handleSearchClick = () => {
    fetchInvoices(partnerId, search, filter, pageNo, funnelType);
    // partnerService.fetchInvoices(partnerId, searchText, pageNo).then((response: any) => {
    //     if (response?.data?.status === 'success') {
    //         setInvoices(response?.data?.data)
    //         setTotalRecords(response?.data?.total)
    //     }
    // }).catch((error: any) => {
    //     toaster.show(true, error?.data?.message);
    // })
  };

  const createNewInvoice = () => {
    Mixpanel.identify(localStorage.getItem("currentUserEmail"));
    Mixpanel.track("My Invoice Section - CTA Create", "");
    Mixpanel.people.set({
      $first_name:
        localStorage.getItem("currentUser") +
        "_" +
        localStorage.getItem("currentRole"),
    });
    navigate(`/invoice/invoice-create/${id}`);
  };

  const fetchInvoices = (partnerId, search, filter, pageNo, funnelType) => {
    partnerService
      .fetchInvoiceListByRetailers(partnerId, id, search, pageNo, filter, funnelType)
      .then((response) => {
        if (response?.data?.status === "success") {
          setInvoices(response?.data?.data);
          setTotalRecords(response?.data?.total);
        }
      })
      .catch((error) => {
        // if (error?.response?.data?.code === "token_not_valid") {
        //   localStorage.clear();
        //   navigate(`/`);
        // } else {
        //   //toaster.show(true, error?.data?.message);
        // }
      });
  };
  const fetchRetailersData = (funnelType, filter) => {
    partnerService
      .fetchGetRetailersById(partnerId, id, funnelType, filter)
      .then((response) => {
        if (response?.data?.status === "success") {
          setRetailersDetails(response?.data?.total_counts);
        }
      })
      .catch((error) => {
        // if (error?.response?.data?.code === "token_not_valid") {
        //   localStorage.clear();
        //   navigate(`/`);
        // } else {
        //   //toaster.show(true, error?.data?.message);
        // }
      });
  };
  
 
  const handleOnboardingDate = (stamp) => {
    if (stamp) {
      const creationTimestamp = stamp;
      const date = new Date(creationTimestamp);
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
      const formattedDate = date.toLocaleDateString("en-GB", options);
      return formattedDate;
    }
  };
  

  const handlePaginationChange = (event, value) => {
    setPageNo(value);
    fetchInvoices(partnerId, search, filter ,value, funnelType);
  };
  const handleFunnelInput = (key, value) => {
    setFunnelType(value)
    fetchRetailersData(value, filter)
    fetchInvoices(partnerId,search, filter, pageNo, value);
  }
  const handleFilterClick = (filter = "", text = "") => {
    filter = filter === "false" ? "" : filter;
    setSearch(text);
    setFilter(filter);

    fetchInvoices(partnerId,text, filter, pageNo, funnelType);
    fetchRetailersData(funnelType, filter)
  };
  
  const handleFilterSearch = (value) => {
    setSearch("");
    setFilter("");

    fetchInvoices(partnerId, value, filter, pageNo, funnelType);
  };
  const funnelData = {
    invoice_count: "23",
    due_amount: "24",
    paid_amount: "28",
    transaction_amount: "30",
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleBack = () => {
    navigate('/retailer/list')
  }
  return (
    <div className="p-4">
      <div className="d-flex justify-content-between mb-2">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.7fr 1fr",
            //gap: "02px",
          }}
        >
                  <button  onClick={handleBack} className="btn btn-primary mr-2" style={{backgroundColor:"#ee3e80", border:"1px solid #ee3e80", padding:"5px, 6px", width:"50%"}}><ArrowBack/></button>     <div style={{ fontSize: "18px", fontWeight: 700, color: "black" }}>
                  <BreadCrumb breadCrumbData={breadCrumbData} />
        </div>

          {/* <div style={{ color:"#EE3E80"}}>
            <BreadCrumb breadCrumbData={breadCrumbData} />
          </div> */}
         
        </div>
        <div onClick={createNewInvoice} style={{ width: 119, height: 40 }}>
          <ActionButton
            isActive={true}
            buttonContent={
              <>
                <AddWhiteSVG /> Invoice
              </>
            }
          />
        </div>
      </div>
      <div>
      <Filters
        filterValues={filterValues}
        viewRole={"RetailersInvoice"}
        handleClick={handleFilterClick}
        searchList={searchList}
        handleSearchClickclear={(value) => handleFilterSearch(value)}
      />
      </div>
      
      <br />
      <div className="retailer-funnel">
              <div className="card">
              <div style={{padding:'15px'}}>Total Invoice- {totalRecords}</div>
                <div className="card-body funnel-body">
                  <div className="top-bar-funnel">
                    <Dropdown
                      list={["Day", "Month", "Overall"]}
                      identifier={"funnel_type"}
                      handleInput={handleFunnelInput}
                      value={funnelType}
                    />
                  </div>
                  <div className="row">
                        <div className="col-md-7 col-sm-12 col-xl-7 col-lg-7 ">
                          <div className="card" style={{height:"100%"}}>
                            <div className="card-body">
                            <div
                            style={{
                              display: "flex",
                              // padding:'10px',
                              justifyContent: "space-between",
                             marginTop:"2rem"
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  color: "#5A607F",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                TOTAL
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px":"15px",
                                  marginTop: "2rem",
                                }}
                              >
                                ₹ {retailersDetails?.total_transaction_amount ?? 0}
                              </div>
                              <div
                               style={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: "12px",
                                marginTop: "2rem",
                                backgroundColor:"#e7dddd",
                                border:"1px solid #e7dddd",
                                padding:'5px 5px',
                                textAlign:"center",
                                borderRadius:"10px"
                              }}
                              >
                                {retailersDetails?.total_invoice_count} Invoice
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#5A607F",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                PAID
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px":"15px",
                                  marginTop: "2rem",
                                }}
                              >
                                ₹ {retailersDetails?.total_paid_amount ?? 0}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  marginTop: "2rem",
                                  backgroundColor:"#e7dddd",
                                  border:"1px solid #e7dddd",
                                  padding:'5px 5px',
                                  textAlign:"center",
                                  borderRadius:"10px"
                                }}
                              >
                                  {retailersDetails?.paid_invoice_count} Invoice
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#5A607F",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                DUE
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px":"15px",
                                  marginTop: "2rem",
                                }}
                              >
                                ₹ {retailersDetails?.total_due_amount ?? 0}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  marginTop: "2rem",
                                  backgroundColor:"#e7dddd",
                                  border:"1px solid #e7dddd",
                                  padding:'5px 5px',
                                  textAlign:"center",
                                  borderRadius:"10px"
                                }}
                              >
                                {retailersDetails?.due_invoice_count} Invoice
                              </div>
                            </div>
                          </div>
                            </div>
                          </div>
                          
                        </div>
                        <div className="col-md-5 col-sm-12 col-xl-5 col-lg-5 h-100" style={{marginTop:isMobile ? '1rem':0}}>
                          <div className="card" style={{height:"100%"}}>
                            <div className="card-body h-100">
                            <Chart title={'Retailers Data'} chartData={retailersDetails} />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                
                </div>
              </div>
            </div>
      <div>
        {invoices.map((invoice) => (
          <div key={invoice.id}>
            <div
              className="card mt-2"
              
            >
              <div className="card-body p-2">
                <div className="listing-wrapper d-flex justify-content-between" onClick={() => navigate("/invoice/" + invoice.invoice_id)}>
                  <div className="left-side-card" >
                    <div className="m-2 "  style={{textAlign:"start", color:"#EE3E80", fontSize: '12px',
    fontWeight: 400}}>{invoice.customer_name}</div>
                    <div className="m-2" style={{textAlign:"start", color: '#5a607f',
    fontSize: '12px',
    fontWeight: 400}}>{invoice.mobile}</div>
                  </div>
                  <div className="right-side-card mr-4">
                    <div className="m-2"  style={{textAlign:"start", color: '#5a607f',
    fontSize: '12px',
    fontWeight: 400}}
             >Invoice ID: {invoice.invoice_id}</div>
                    <div className="d-flex justify-content-end m-2" style={{textAlign:"start", color: '#5a607f',
    fontSize: '12px',
    fontWeight: 400}}>
                      {handleOnboardingDate(invoice.invoice_date)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4 d-flex justify-content-center">
        <Pagination
          count={Math.ceil(totalRecords / 20)}
          variant="outlined"
          shape="rounded"
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default RetailerInvoice;
