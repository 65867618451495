import React, { useEffect } from "react";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./business-slider.scss";
import { Modal } from "react-bootstrap";

function BusinessSliderVideo({ sliderImages }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeImageNum, setCurrent] = useState(0);
  const length = sliderImages.length;

  const nextSlide = () => {
    setCurrent(activeImageNum === length - 1 ? 0 : activeImageNum + 1);
  };
  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? length - 1 : activeImageNum - 1);
  };
  if (!Array.isArray(sliderImages) || sliderImages.length <= 0) {
    return null;
  }
  return (
    <div class="container">
      <section className="image-slider">
        <div class="col-md-2">
          <div class="left-slider-btn">
            <ArrowBackIosIcon onClick={prevSlide} />
          </div>
        </div>

        <div class="col-md">
          {sliderImages.map((currentSlide, ind) => {
            return (
              <div
                className={
                  ind === activeImageNum
                    ? "currentSlide active"
                    : "currentSlide"
                }
                key={ind}
              >
                {ind === activeImageNum && (
                  <div style={{ width: "180px", height: "180px" }}>
                    <div style={{ padding: "10px" }}>
                      {" "}
                      <video
                        controls
                        onClick={() => handleShow()}
                        style={{
                          cursor: "pointer",
                          width: "200px",
                          height: "200px",
                        }}
                      >
                        <source src={currentSlide} type="video/mp4" />
                        <source src={currentSlide} type="video/webm" />
                      </video>{" "}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div class="col-md-2">
          <div class="right-slider-btn">
            <ArrowForwardIosIcon onClick={nextSlide} />
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            {" "}
            <video
              controls
              onClick={() => handleShow()}
              style={{
                cursor: "pointer",
                width: "400px",
                height: "400px",
              }}
            >
              <source src={sliderImages[activeImageNum]} type="video/mp4" />
              <source src={sliderImages[activeImageNum]} type="video/webm" />
            </video>
          </div>
        </Modal>
      </section>
    </div>
  );
}

export default BusinessSliderVideo;
