import "./details-box.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dropdown from "../dropdown/DropDown";
import { useEffect, useState } from "react";

function BasicDetails({
  basicData,
  heading,
  productList,
  handleProductType,
  productType,
  iceStatus,
  additionalData,
}) {
  let cred_declaration_status = <>{additionalData?.credit_declaration}</>;

  if (additionalData?.credit_declaration === "No Match") {
    cred_declaration_status = (
      <>
        {additionalData?.credit_declaration}
        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (additionalData?.credit_declaration === "Match") {
    cred_declaration_status = (
      <>
        {additionalData?.credit_declaration}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  let location = <>{basicData?.location}</>;

  if (basicData?.location === "No Match") {
    location = (
      <>
        {basicData?.location}
        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (basicData?.location === "Match") {
    location = (
      <>
        {basicData?.location}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  const [basicDataMappingOne, setDataOne] = useState({});

  const [basicDataMappingTwo, setDataTwo] = useState({});

  const [basicDataMappingThree, setDataThree] = useState({});

  const [basicDataMappingFour, setDataFour] = useState({});

  const [basicDataMappingFive, setDataFive] = useState({});

  useEffect(() => {
    const dataOne = {
      "Customer ID": basicData?.customer_id,
      Partner: basicData?.partner,
      "Customer Type": basicData?.customer_type,
      Center: basicData?.center,
      Product: basicData?.product,
    };

    const dataTwo = {
      "New to credit": basicData?.new_to_credit,
      "ARTH Active Loans": basicData?.arth_active_loans,
      "Post Credit Report": basicData?.post_credit_report,
      "Credit Declaration": cred_declaration_status,
      Location: location,
    };
    processData(dataOne, setDataOne);

    const dataFive = {
      "Last Offer Amount": basicData?.last_offer_amount,
      "Last Offer Tenure": basicData?.last_offer_tenure,
      "Last Offer Generated At": basicData?.last_offer_generated_at,
    };

    const dataFour = {
      "Default Amount": basicData?.default_amount
        ? nf.format(basicData.default_amount)
        : "0",
      "Default Tenure": basicData?.default_tenure,
    };

    const dataThree = { "Arth Executive": basicData?.arth_executive };

    processData(dataTwo, setDataTwo);

    processData(dataThree, setDataThree);

    processData(dataFour, setDataFour);

    processData(dataFive, setDataFive);
  }, []);

  const processData = (data, setData) => {
    const dataDict = {};
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        dataDict[key] = data[key];
      }
    });
    setData(dataDict);
  };
  const nf = new Intl.NumberFormat();

  return (
    <div className={`basic-details-container`}>
      <div class="row">
        <div class="col-md hdr-style">Basic Details</div>
      </div>
      <div>
        {Object.keys(basicDataMappingOne).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>
              {[
                "Loan against property",
                "Home Loan",
                "Balance Transfer",
                "Loan Against Property (LAP)",
                "Home Loan (HL)",
                "Balance Transfer (BT)",
              ].includes(basicData?.product) ? (
                <>{basicDataMappingOne[key]}</>
              ) : (
                <>
                  {key === "Product" &&
                  iceStatus !== "Accepted" &&
                  iceStatus !== "Rejected" ? (
                    <>
                      <Dropdown
                        heading={""}
                        placeholder={""}
                        value={productType}
                        multiple={false}
                        isCompulsoryField={false}
                        // supportiveTextRed={(form['branch_name'] === '' && isFormSubmitted) && 'Please select business category.'}
                        list={productList}
                        handleInput={handleProductType}
                        identifier={"producttype"}
                      />
                    </>
                  ) : (
                    basicDataMappingOne[key]
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {Object.keys(basicDataMappingTwo).length != 0 && (
        <div>
          <div className="seperator" />
          {Object.keys(basicDataMappingTwo).map((key) => (
            <div class={`row basic-details-body`}>
              <div class="col-md-auto basic-left-body">{key}</div>
              <div class={`col-md basic-right-body`}>
                {basicDataMappingTwo[key]}
              </div>
            </div>
          ))}
        </div>
      )}
      {Object.keys(basicDataMappingThree).length != 0 && (
        <>
          <div className="seperator" />
          <div>
            {Object.keys(basicDataMappingThree).map((key) => (
              <div class={`row basic-details-body`}>
                <div class="col-md-auto basic-left-body">{key}</div>
                <div class={`col-md basic-right-body`}>
                  {basicDataMappingThree[key]}
                </div>
              </div>
            ))}
          </div>{" "}
        </>
      )}

      {Object.keys(basicDataMappingFour).length != 0 && (
        <>
          <div className="seperator" />
          <div>
            {Object.keys(basicDataMappingFour).map((key) => (
              <div class={`row basic-details-body`}>
                <div class="col-md-auto basic-left-body">{key}</div>
                <div class={`col-md basic-right-body`}>
                  {basicDataMappingFour[key]}
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {Object.keys(basicDataMappingFive).length != 0 && (
        <>
          <div className="seperator" />
          <div>
            {Object.keys(basicDataMappingFive).map((key) => (
              <div class={`row basic-details-body`}>
                <div class="col-md-auto basic-left-body">{key}</div>
                <div class={`col-md basic-right-body`}>
                  {basicDataMappingFive[key]}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default BasicDetails;
